import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Switch,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Datepicker } from '../../components/Datepicker'

import { FormGroup } from '../../components/FormGroup'
import { FormLabel } from '../../components/FormLabel'
import {
  FormSection,
  FormSectionBody,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { InputGroup } from '../../components/InputGroup'
import { SwitchControl } from '../../components/SwitchControl'
import { TextEditor } from '../../components/TextEditor'
import { ArrowLeft } from '../../icons'
import { CompaniesSelect } from '../CompaniesSelect'
import { FormButtons } from '../FormButtons'
import { getNewsItemTypeBadge } from './NewsItem'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { INewsNewItemFormValues } from '../../model/interfaces/entities/INews'
import * as Yup from 'yup'
import { INewsFormProps } from '../../model/interfaces/entities/INewsFormProps'
import { ICompanyV2 } from '../../model/interfaces/entities/ICompany'

const NewsSchema = Yup.object().shape({
  title: Yup.string().required('You need to complete this field!'),
  priority: Yup.string().required('You need to select an option!'),
  type: Yup.string().required('You need to select an option!'),
})

export const NewsForm = (props: INewsFormProps) => {
  const { initialValues, onSubmitForm, isLoading } = props
  const [date, setDate] = useState(new Date())
  const [htmlContent, setHtmlContent] = useState('')
  const [fieldIsActive, setFieldIsActive] = useState(initialValues.isActive)
  const [defaultCompany, setDefaultCompany] = useState<ICompanyV2['id'] | undefined>(undefined)

  useEffect(() => {
    if (initialValues) {
      setFieldIsActive(initialValues.isActive)
      setDefaultCompany(initialValues.company)
    }
  }, [initialValues])

  const onSubmit = (values: INewsNewItemFormValues) => {
    values.date = date.toLocaleDateString('en-CA')
    values.content = htmlContent
    values.company = Number(values.company)
    values.isActive = fieldIsActive
    onSubmitForm(values)
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={NewsSchema}
          enableReinitialize={true}
        >
          {({ handleChange, errors, touched }) => (
            <Form>
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Obsah aktuality</FormSectionTitle>
                </FormSectionHeader>

                <FormSectionBody>
                  <FormGroup>
                    <FormControl>
                      <FormLabel htmlFor="date">Datum</FormLabel>
                      <Datepicker
                        autoComplete="date"
                        date={date}
                        onDateChange={(date: Date) => setDate(date)}
                      />
                    </FormControl>
                  </FormGroup>

                  <FormControl
                    variant="fullWidth"
                    isInvalid={touched.title && !!errors.title}
                    id="title"
                  >
                    <FormLabel htmlFor="title">Nadpis</FormLabel>

                    <InputGroup
                      id="title"
                      defaultValue={initialValues.title}
                      onChange={handleChange}
                      placeholder="Nadpis aktuality"
                      maxLength={255}
                    />
                    {touched.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
                  </FormControl>

                  <FormControl variant="fullWidth">
                    <FormLabel>Text</FormLabel>

                    <TextEditor
                      id="content"
                      defaultContent={initialValues.content}
                      placeholder="Vložte text aktuality..."
                      onChange={(value) => setHtmlContent(value)}
                      maxLength={65000}
                    />
                  </FormControl>
                </FormSectionBody>
              </FormSection>

              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Nastavení aktuality</FormSectionTitle>
                </FormSectionHeader>

                <FormSectionBody>
                  <FormControl>
                    <FormLabel>Zobrazit aktualitu</FormLabel>

                    <SwitchControl>
                      <FormLabel htmlFor="isActive" variant="switch">
                        Ne
                      </FormLabel>
                      <Switch
                        size="md"
                        id="isActive"
                        isChecked={fieldIsActive}
                        onChange={() => setFieldIsActive(!fieldIsActive)}
                        onBlur={handleChange}
                      />
                      <FormLabel htmlFor="isActive" variant="switch">
                        Ano
                      </FormLabel>
                    </SwitchControl>
                  </FormControl>

                  <Field name="priority">
                    {({ field }: FieldProps) => {
                      const { onChange, ...rest } = field

                      return (
                        <FormControl
                          id="priority"
                          isInvalid={touched.priority && !!errors.priority}
                        >
                          <FormLabel>Priorita</FormLabel>

                          <RadioGroup {...rest} name="priority">
                            <HStack sx={{ h: '36px' }}>
                              <Radio onChange={handleChange} {...rest} value="standard">
                                Standardní
                              </Radio>
                              <Radio onChange={handleChange} {...rest} value="high">
                                Vysoká
                              </Radio>
                            </HStack>
                          </RadioGroup>
                          {touched.priority && (
                            <FormErrorMessage>{errors.priority}</FormErrorMessage>
                          )}
                        </FormControl>
                      )
                    }}
                  </Field>
                  <Field name="type">
                    {({ field }: FieldProps) => {
                      const { onChange, ...rest } = field

                      return (
                        <FormControl id="type" isInvalid={touched.type && !!errors.type}>
                          <FormLabel>Druh aktuality</FormLabel>
                          <RadioGroup {...rest} name="type">
                            <HStack sx={{ h: '36px' }}>
                              <Radio onChange={handleChange} {...rest} value="info">
                                {getNewsItemTypeBadge('info')}
                              </Radio>
                              <Radio onChange={handleChange} {...rest} value="announcement">
                                {getNewsItemTypeBadge('announcement')}
                              </Radio>
                              <Radio onChange={handleChange} {...rest} value="warning">
                                {getNewsItemTypeBadge('warning')}
                              </Radio>
                              <Radio onChange={handleChange} {...rest} value="danger">
                                {getNewsItemTypeBadge('danger')}
                              </Radio>
                            </HStack>
                          </RadioGroup>
                          {touched.type && <FormErrorMessage>{errors.type}</FormErrorMessage>}
                        </FormControl>
                      )
                    }}
                  </Field>

                  {
                    <FormControl id="company" isInvalid={!!errors.company}>
                      <FormLabel>Vztah k pojišťovně</FormLabel>

                      <CompaniesSelect
                        onChange={(e) => setDefaultCompany(Number(e.target.value))}
                        onBlur={handleChange}
                        id="company"
                        value={defaultCompany}
                      />
                    </FormControl>
                  }
                </FormSectionBody>
              </FormSection>

              <FormButtons
                leftButtons={
                  <Button as={Link} to="/news/admin" type="button" variant="ghost">
                    <ArrowLeft color="secondary.500" />
                    Zpět
                  </Button>
                }
                rightButtons={
                  <Button type="submit" colorScheme="primary" isLoading={isLoading}>
                    Uložit
                  </Button>
                }
              />
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}
