import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IFlexiGroupEditFormValues } from '../../interfaces/entities/IFlexiGroup'

export const useGetFlexiGroupId = (id: string | undefined) => {
  const flexiGroupDetails: string = ApiRouteEnum.FLEXI_DETAIL.replace('<id>', id ?? '')
  return useQuery(
    [ApiRouteEnum.FLEXI_DETAIL, id],
    () => api.get<IFlexiGroupEditFormValues>(flexiGroupDetails),
    {
      retry: false,
    },
  )
}
