import { INewsItem } from '../../data/news'
import { NewsItem } from './NewsItem'
import { INewsItemV2 } from '../../model/interfaces/entities/INews'
import { Section } from '../../components/Section'
import { Box, Button, Flex, useMultiStyleConfig } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import React from 'react'

interface INewsList {
  news?: Array<INewsItemV2>
}

export const NewsList = ({ news }: INewsList) => {
  const styles = useMultiStyleConfig('NewsItem')

  return (
    <>
      {news &&
        news.length > 0 &&
        news.map(({ id, app, title, date, tagLabel, status, unread, company, type, content }) => {
          return (
            <NewsItem
              variant="list"
              key={id}
              app={app}
              id={id}
              title={title}
              date={date}
              status={status}
              tagLabel={tagLabel}
              unread={unread}
              company={company}
              content={content}
              type={type}
            />
          )
        })}
      <Box sx={{ ...styles.container }}>
        <Flex
          sx={{
            w: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'end',
          }}
        >
          <Button as={Link} to={'/news'} variant="outline" size="sm">
            Zobrazit více
          </Button>
        </Flex>
      </Box>
    </>
  )
}
