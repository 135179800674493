// Dev mode is based on Cookie "devMode=true"
const DEV_MODE_MAX_LIMIT = 50;

export const useIsDevMode = () => {
	return isDevMode();
}

export const useQueryLimit = (limit?: number): number|undefined => {
	if (!limit) {
		return limit;
	}
	return isDevMode() && limit > DEV_MODE_MAX_LIMIT
		? DEV_MODE_MAX_LIMIT
		: limit;
}

export const isDevMode = () => document.cookie.includes("devMode=true");
