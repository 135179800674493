import { FormLabel, Switch } from '@chakra-ui/react'
import { SwitchControl } from '../../components/SwitchControl'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'

export const ShowInactiveSwitch = ({
  queryParams,
  setQueryParams,
}: {
  queryParams: IQueryParams
  setQueryParams: Function
}) => {
  return (
    <SwitchControl>
      <Switch
        size="md"
        id="showInactive"
        isChecked={queryParams.showInactive === 1}
        onChange={(e) => {
          setQueryParams({ ...queryParams, page: 1, showInactive: e.target.checked ? 1 : undefined })
        }}
      />
      <FormLabel htmlFor="showInactive" variant="switch">
        Zobrazit i neaktivní
      </FormLabel>
    </SwitchControl>
  )
}
