import { FormControl, FormErrorMessage, SelectProps } from '@chakra-ui/react'

import { FormGroup } from '../components/FormGroup'
import { FormLabel } from '../components/FormLabel'
import { InputGroup, InputGroupProps } from '../components/InputGroup'
import { IApplicant } from '../data/InsuranceForm'

export interface InsureeAddressProps {
  isDisabled?: boolean
  inputGroupProps?: InputGroupProps
  selectProps?: SelectProps
  postalCodeId?: string
  countryId?: string
  cityId?: string
  values?: Partial<IApplicant>
  setValues?: Function
  errors?: any
  touched?: any
}

export const InsureeAddress = (props: InsureeAddressProps) => {
  const {
    isDisabled = false,
    postalCodeId = 'postalCode',
    values,
    setValues,
    errors,
    touched,
  } = props

  return (
    <FormGroup>
      <FormControl isInvalid={touched.applicant?.zip && !!errors.applicant?.zip}>
        <FormLabel htmlFor={postalCodeId}>
          PSČ
        </FormLabel>
        <InputGroup
          id={postalCodeId}
          placeholder="00000"
          autoComplete="postal-code"
          htmlSize={4}
          isDisabled={true}
          value={values?.zip}
          onChange={(e) => setValues && setValues({ ...values, zip: e.target.value })}
        />
        {touched.applicant?.zip && <FormErrorMessage>{errors.applicant?.zip}</FormErrorMessage>}
      </FormControl>

      <FormControl isInvalid={touched.applicant?.city && !!errors.applicant?.city}>
        <FormLabel htmlFor={'cityId'}>Město</FormLabel>

        <InputGroup
          id="cityId"
          htmlSize={24}
          placeholder="Prague"
          value={values?.city}
          onChange={(e) => setValues && setValues({ ...values, city: e.target.value })}
          isDisabled={true}
        />
        {touched.applicant?.city && <FormErrorMessage>{errors.applicant?.city}</FormErrorMessage>}
      </FormControl>
    </FormGroup>
  )
}
