import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Loading } from '../../components/Loading'
import { TableWrapper } from '../../components/TableWrapper'
import { getUserStatus, getUserType } from '../../data/profile'
import { useGetUser } from '../../model/api/user/useGetUser'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { IUserItem } from '../../model/interfaces/entities/IUser'
import { PageToolbar } from '../../containers/PageToolbar'
import { SearchField } from '../../containers/SearchField'
import { ShowInactiveSwitch } from '../../containers/ShowInactiveSwitch'
import { appendOrRemoveQueryParameters } from '../../model/utils/urlUtils'
import { omit } from 'lodash'
import { Section } from '../../components/Section'
import { Export } from '../../icons'
import { useGetUserExport } from '../../model/api/user/useGetUserExport'
import { userRoleToLabel } from '../../model/utils/userUtils'
import { useIsDevMode } from '../../model/hooks/useIsDevMode'

const UserRow = (user: IUserItem, isActive: boolean) => {
  const { colorScheme: statusColorScheme, label: statusLabel } = getUserStatus(user.isActive)
  const { colorScheme: userTypeColorScheme, label: userTypeLabel } = getUserType(user.userType)

  return (
    <>
      <Tr>
        <Td>
          <Text sx={{ color: 'primary.500', fontWeight: 'medium' }}>
            <Link to={`/user/detail/${user.id}`}>
              {user.lastName} {user.firstName}
            </Link>
          </Text>
        </Td>
        <Td>
          <Text sx={{ color: 'gray.500' }}>{user.username}</Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.700' }}>{user.email}</Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.700' }}>{user.role && userRoleToLabel(user.role)}</Text>
        </Td>
        <Td>
          <Flex sx={{ gap: 1, justifyContent: 'start' }}>
            {isActive && <Badge colorScheme={statusColorScheme}>{statusLabel}</Badge>}
            {user.userType && <Badge colorScheme={userTypeColorScheme}>{userTypeLabel}</Badge>}
          </Flex>
        </Td>
      </Tr>
    </>
  )
}

export const USER_LIST_PREFIX = 'organizationUser-'

export const UserListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [queryParams, setRawQueryParams] = useState<{
    search?: string
    unreadOnly?: number
    limit: number
  }>({
    unreadOnly: searchParams.get(USER_LIST_PREFIX + 'unreadOnly') === '0' ? undefined : 1,
    search: searchParams.get(USER_LIST_PREFIX + 'search')
      ? searchParams.get(USER_LIST_PREFIX + 'search')!
      : undefined,
    limit: useIsDevMode() ? 50 : 1_000_000,
  })

  const setQueryParams = (params: IQueryParams): void => {
    const newSearchParams = appendOrRemoveQueryParameters(
      omit(params, ['limit']),
      searchParams,
      USER_LIST_PREFIX,
    )

    newSearchParams.delete(`${USER_LIST_PREFIX}unreadOnly`)
    if (typeof params.unreadOnly === 'undefined') {
      newSearchParams.set(`${USER_LIST_PREFIX}unreadOnly`, '0')
    }

    setSearchParams(newSearchParams)
    setRawQueryParams(params)
  }

  const { data, isError, isLoading, error } = useGetUser({
    limit: queryParams.limit,
    search: queryParams.search,
    unreadOnly: queryParams.unreadOnly,
  })

  const toastMessage = useToast()

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError, error, toastMessage])

  const downloadUserExportCSV = (csvString: string) => {
    const currentDate = new Date()
    const day = currentDate.getDate().toString().padStart(2, '0')
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const year = currentDate.getFullYear().toString()
    const formattedDate = `${day}_${month}_${year}`

    const csvData = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvString], {
      type: 'text/csv;charset=utf-8',
    })
    const link = document.createElement('a')
    link.download = `uzivatele-export-${formattedDate}.csv`
    link.href = window.URL.createObjectURL(csvData)
    link.click()
  }

  const getUserExport = useGetUserExport(
    downloadUserExportCSV,
    queryParams.unreadOnly,
    queryParams.search,
  )

  return (
    <Section>
      <Container>
        <Box sx={{ display: 'grid', gap: 4 }}>
          <PageToolbar
            leftElements={<Heading>Seznam uživatelů</Heading>}
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <Button colorScheme="gray" onClick={() => getUserExport.mutate()}>
                  <Export />
                  Exportovat
                </Button>
              </Flex>
            }
          />
          <Flex sx={{ gap: 4, justifyContent: 'space-between' }}>
            <SearchField
              inputPlaceholder="Hledat dle jména"
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
            <ShowInactiveSwitch queryParams={queryParams} setQueryParams={setQueryParams} />
          </Flex>

          {isLoading ? (
            <Loading />
          ) : (
            <TableWrapper>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Td>Jméno</Td>
                    <Td>Uživatelské jméno</Td>
                    <Td>E-mail</Td>
                    <Td>Role</Td>
                    <Td></Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <>
                    {data?.data.items &&
                      data.data.items.length > 0 &&
                      data.data.items.map((user: IUserItem) => {
                        return <UserRow key={user.id} {...user} />
                      })}
                  </>
                </Tbody>
              </Table>
            </TableWrapper>
          )}
        </Box>
      </Container>
    </Section>
  )
}
