export const IS_REQUIRED = 'Povinné pole'
export const INVALID_IDENTIFICATION_NUMBER = 'Neplatné rodné číslo'
export const INVALID_EMAIL = 'Neplatná e-mailová adresa'
// eslint-disable-next-line no-template-curly-in-string
export const MIN_CHARACTERS = 'Minmální množství znaků: ${min}'
// eslint-disable-next-line no-template-curly-in-string
export const MAX_CHARACTERS = 'Maximální množství znaků: ${max}'

export const IS_NUMBER = 'Musí být číslo'

// eslint-disable-next-line no-template-curly-in-string
export const MIN_NUMBER = 'Číslo musí být alespoň ${min}'
// eslint-disable-next-line no-template-curly-in-string
export const MAX_NUMBER = 'Číslo musí být měnší než ${max}'
