import React from 'react'
import { Box } from '@chakra-ui/react'

interface PageToolbarProps {
  leftElements?: React.ReactNode
  rightElements?: React.ReactNode
}

export const PageToolbar = ({ leftElements, rightElements }: PageToolbarProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        w: '100%',
        gridTemplateColumns: { base: '1fr', lg: 'auto auto' },
        alignItems: 'start',
        justifyContent: 'space-between',
        rowGap: 4,
      }}
    >
      <Box sx={{ order: {base: 1, lg: 0} }}>
        {leftElements && leftElements}
      </Box>

      <Box sx={{ order: {base: 0, lg: 1} }}>
        {rightElements && rightElements}
      </Box>
    </Box>
  )
}
