import { useState, useRef, useMemo, useEffect } from 'react'
import JoditEditor from 'jodit-react'
import type { IJodit } from 'jodit/types'
import { forwardRef } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'

import { cs_cz } from './cs_cz'
import {
  TextAlignCenterString,
  TextAlignJustifyString,
  TextAlignLeftString,
  TextAlignRightString,
  TextBoldString,
  TextItalicsString,
  TextOrderedListString,
  TextUnorderedListString,
} from '../../icons'

const getCustomIcon = (name: string, control: any, clearName: string) => {
  switch (clearName) {
    case 'bold':
      return TextBoldString
    case 'italic':
      return TextItalicsString

    case 'image':
      return

    case 'left':
      return TextAlignLeftString
    case 'right':
      return TextAlignRightString
    case 'justify':
      return TextAlignJustifyString
    case 'center':
      return TextAlignCenterString

    case 'ul':
      return TextUnorderedListString
    case 'ol':
      return TextOrderedListString
  }
}

export const TextEditor = forwardRef(
  (
    {
      placeholder,
      onChange,
      defaultContent,
      maxLength,
    }: { placeholder?: string; onChange: (arg0: any) => void; defaultContent?: string, maxLength?: number },
    ref,
  ) => {
    const editor = useRef<any>(null)
    const [content, setContent] = useState(defaultContent ? defaultContent : '')
    useEffect(() => {
      defaultContent && setContent(defaultContent)
    }, [defaultContent])

    const config = useMemo(() => {
      const config: IJodit['options'] = {
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: placeholder || 'Vložte text...',
        statusbar: false,
        font: '12px Inter',
        width: '100%',
        maxWidth: '100%',
        language: 'cs_cz',
        limitChars: maxLength ?? undefined,
        i18n: {
          cs_cz,
        },
        events: {
          getIcon: getCustomIcon,
        },
      } as any

      return config
    }, [placeholder])

    useEffect(() => {
      editor && editor.current && console.log(editor.current.component)
    }, [editor])

    return (
      <>
        <Global
          styles={css`
            .jodit-container:not(.jodit_inline) {
              border-color: var(--ok-colors-gray-400) !important;
              border-radius: var(--ok-radii-md) !important;
            }

            .jodit-toolbar__box:not(:empty),
            .jodit-toolbar__box {
              background-color: var(--ok-colors-gray-50) !important;
              border-radius: var(--ok-radii-md) var(--ok-radii-md) 0 0 !important;
            }

            .jodit-toolbar-button .jodit-icon {
              width: 1rem !important;
              height: 1rem !important;
            }

            svg.jodit-icon {
              fill: var(--ok-colors-blue-800);
              stroke: var(--ok-colors-blue-800);
              stroke-width: 0 !important;
            }
          `}
        />
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          onBlur={(newContent: string) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent: string) => onChange(newContent)}
        />
      </>
    )
  },
)
