import React from 'react'
import {
  Alert,
  Box,
  Button,
  FormLabel, Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { DownloadButton } from '../DownloadButton'
import { IDocument } from '../../model/interfaces/entities/IDocument'
import { useParams } from 'react-router-dom'
import { usePostContractSendMail } from '../../model/api/contracts/usePostContractSendMail'
import ModalEmailFormComplete from '../../components/ModalEmailFormComplete'
import useGetSentEmails, {
  EmailSendType,
  EmailStatus, IEmail,
} from '../../model/api/emailForm/useGetSentEmails'
import moment from 'moment'
import { Loading } from '../../components/Loading'


export const FailedSendMailAlert: React.FC<{sentEmail: IEmail; successSendMailCallBack: any, errorSendMailCallBack: any}> = ({
  sentEmail,
  successSendMailCallBack,
  errorSendMailCallBack,
}) => {
  const { isLoading, mutate: sendMailMutate } = usePostContractSendMail(
    successSendMailCallBack,
    errorSendMailCallBack,
    sentEmail.contract.toString(),
  )

  return (
    <Alert colorScheme={'red'}>
      E-mail na pojistníka se nepodařilo odeslat.
      {isLoading
        ? <span style={{marginLeft: 5}}>
          <Spinner size="xs" /> Odesílání...
        </span>
        : <span>
          &nbsp;<Link onClick={() => {sendMailMutate({email: sentEmail.recipientEMailAddress})}}>Odešlete znovu.</Link>
        </span>}
    </Alert>
  )
}

export const InsuranceDownloads: React.FC<{ documents: IDocument[]; insureeEmail?: string }> = ({
  documents,
  insureeEmail,
}) => {
  const openDocument = (url: string) => {
    window.open(url, '_blank')
  }
  const toastMessage = useToast()
  const { id } = useParams<'id'>()
  const successSendMailCallBack = (data: any) => {
    onEmailModalClose()
    toastMessage({
      title: 'Odesláno',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }

  const errorSendMailCallBack = (error: any) => {
    toastMessage({
      title: error.response?.data.error,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }
  const { isLoading, mutate: sendMailMutate } = usePostContractSendMail(
    successSendMailCallBack,
    errorSendMailCallBack,
    id,
  )

  const { isLoading: sentEmailsIsLoading, data: sentEmailsData } = useGetSentEmails(id)

  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure()

  const emailAlert = (sentEmail: IEmail) => {
    if (sentEmail.status === EmailStatus.EMAIL_STATUS_SENT_OK) {
      if (sentEmail.sendType === EmailSendType.SENT_AUTOMATICALLY) {
        return <Alert colorScheme={'green'}>
          E-mail na pojistníka byl automaticky odeslán dne {moment(sentEmail.sentAt).format('DD.MM.Y HH:mm')} na adresu {sentEmail.recipientEMailAddress}.
        </Alert>
      } else {
        return <Alert colorScheme={'green'}>
          E-mail na pojistníka byl manuálně odeslán dne {moment(sentEmail.sentAt).format('DD.MM.Y HH:mm')} na adresu {sentEmail.recipientEMailAddress}.
        </Alert>
      }
    } else {
      return <FailedSendMailAlert sentEmail={sentEmail} successSendMailCallBack={successSendMailCallBack} errorSendMailCallBack={errorSendMailCallBack} />
    }
  }

  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>Dokumenty k pojištění</FormSectionTitle>

        <FormSectionDescription>
          <Button type="button" colorScheme="primary" onClick={onEmailModalOpen}>
            Odeslat e-mail klientovi
          </Button>
          <Modal isOpen={isEmailModalOpen} onClose={onEmailModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>E-mail</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ModalEmailFormComplete
                  isLoading={isLoading}
                  insureeEmail={insureeEmail}
                  onMailMutate={sendMailMutate}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </FormSectionDescription>
      </FormSectionHeader>

      <FormSectionBody>
        <FormLabel>Dokumenty</FormLabel>
        <Box sx={{ display: 'grid', gap: 0, placeItems: 'start' }}>
          {documents.map((document) => (
            <Box sx={{ display: 'grid', gap: 0, placeItems: 'start' }} key={document.id}>
              <DownloadButton onClick={() => openDocument(document.url)} isDownloadAll>
                {document.name}
              </DownloadButton>
            </Box>
          ))}
        </Box>
        <FormLabel>Historie odeslaných e-mailů</FormLabel>
        <Box sx={{ display: 'grid', gap: 0, placeItems: 'start', width: '100%' }}>
          {sentEmailsData?.data.items.map((sentEmail, key) => (
            <Box sx={{ display: 'grid', gap: 0, placeItems: 'start', width: '100%' }} key={key}>
              {emailAlert(sentEmail)}
            </Box>
          ))}
        </Box>
      </FormSectionBody>
    </FormSection>
  )
}
