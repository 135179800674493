import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

import { IUser } from '../../interfaces/entities/IUser'
import queryString from 'query-string'
import { useQueryLimit } from '../../hooks/useIsDevMode'

export const useGetUser = ({
  page,
  limit,
  search,
  unreadOnly,
  organizationId,
  role,
  disabled,
  flexiGroup,
  notInFlexiGroup
}: {
  page?: number | undefined,
  limit?: number,
  search?: string,
  unreadOnly?: number,
  organizationId?: string,
  role?: string,
  disabled?: boolean,
  flexiGroup?: string,
  notInFlexiGroup?: number,
}) => {
  const query = queryString.stringify(
    {
      page,
      limit: useQueryLimit(limit),
      isActive: unreadOnly,
      search,
      organizationId,
      role,
      flexiGroup,
      notInFlexiGroup
    },
    {
      skipEmptyString: true,
    },
  )

  const filterUser: string = `${ApiRouteEnum.USER}?${query}`
  return useQuery([ApiRouteEnum.USER, query], () => api.get<IUser>(filterUser), {
    retry: false,
    enabled: !disabled,
  })
}
