import React, { useContext, useEffect } from 'react'
import {
  Button,
  Checkbox,
  Flex,
  FormErrorMessage,
  FormHelperText,
  Radio,
  RadioGroup, Select,
  Tooltip,
} from '@chakra-ui/react'
import { FormGroup } from '../../components/FormGroup'
import { FormLabel } from '../../components/FormLabel'
import { FormSpacer } from '../../components/FormSpacer'
import { InputGroup } from '../../components/InputGroup'
import { Business, Cloud, Company, Help, Info, LabelHintIcon } from '../../icons'
import { InsureeAddress } from '../InsureeAddress'
import { RadioStack } from '../../components/RadioStack'
import { IApplicant } from '../../data/InsuranceForm'
import AddressAutocomplete from '../../components/AddressAutocomplete'
import { IAddressSuggestion } from '../../model/interfaces/entities/IAddressSuggestion'
import {
  transformAddressToStreet,
  transformAddressToStreetNumber,
} from '../../model/utils/addressUtils'
import api from '../../model/api/api'
import { IAres } from '../../model/interfaces/entities/IAres'
import { ApiRouteEnum } from '../../model/enums/ApiRouteEnum'
import { InsureeTypeEnum } from '../../model/enums/InsureeTypeEnum'
import { FormControl } from '../../components/FormControl'
import { FormIsDisabledContext } from '../../model/contexts/FormIsDisabledContext'
import { RequiredField } from '../RequiredField'
import { useGetContracts } from '../../model/api/contracts/useGetContracts'
import { useGetUserDegrees } from '../../model/api/user/useGetUserDegrees'
import { IItem } from '../../model/interfaces/entities/IItem'
import { isString } from 'formik'

export interface AdditionalInsureeSmeInfoProps {
  hideContacts?: boolean
  values: Partial<IApplicant>
  setValues: Function
  itemValues?: IItem
  touched: any
  errors: any
  extended?: boolean
  showAddress?: boolean
  showSkipAddress?: boolean
  isAddressSameAsPlace?: boolean
  onInsureeEmailValue?: (email: string) => void,
  useTitlesEnum?: boolean
}

const RadioLabelWithIcon = ({ children }: { children: React.ReactNode }) => {
  return <Flex sx={{ gap: 2, alignItems: 'center' }}>{children}</Flex>
}

export const AdditionalInsureeSmeInfo = ({
  values,
  setValues,
  itemValues = undefined,
  touched,
  errors,
  extended,
  showAddress = false,
  showSkipAddress = false,
  isAddressSameAsPlace = false,
  onInsureeEmailValue,
  useTitlesEnum = false,
}: AdditionalInsureeSmeInfoProps) => {
  const [aresIsLoading, setAresIsLoading] = React.useState(false)
  const [aresFailed, setAresFailed] = React.useState(false)
  const formIsDisabled = useContext(FormIsDisabledContext)

  React.useEffect(() => {
    if (isAddressSameAsPlace && showSkipAddress && !values.addressSameAsPlace) {
      setValues({
        ...values,
        addressSameAsPlace: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressSameAsPlace, showSkipAddress])

  const { data: dataUserDegrees, isLoading: isLoadingUserDegrees } = useGetUserDegrees({})

  const fetchAres = async () => {
    if (!values.ino || aresIsLoading) return

    try {
      setAresIsLoading(true)
      const response = await api.get<IAres>(`${ApiRouteEnum.ARES}/${values.ino}`)
      const { name, street, city, zip, ruianId, streetNumber, vatin } = response.data

      setValues({
        ...values,
        companyName: name,
        street: street || city,
        city,
        zip,
        ruianId: Number(ruianId),
        streetNumber: streetNumber || '',
        vatPayer: !!vatin,
      })
      setAresFailed(false)
    } catch (err) {
      setAresFailed(true)
      console.log({ err })
    } finally {
      setAresIsLoading(false)
    }
  }

  const isGcp = () => {
    if (itemValues === undefined) {
      return false;
    }

    return itemValues.company.hasCommunicationConsentDuring;
  }

  useEffect(() => {
    console.log(values);
    if (typeof values.title === 'string') {
      setValues({ ...values, title: null })
    }
  }, [])

  return (
    <>
      <FormControl
        id="applicant.type"
        isInvalid={touched.applicant?.type && !!errors.applicant?.type}
        isDisabled={extended || formIsDisabled}
      >
        <FormLabel>
          Typ
          <RequiredField />
        </FormLabel>

        <RadioGroup
          name="applicant.type"
          onChange={(e) => setValues({ ...values, type: e })}
          defaultValue={values.type}
        >
          <RadioStack variant="tile">
            <Radio value={InsureeTypeEnum.PERSON}>
              <RadioLabelWithIcon>
                Fyzická osoba podnikatel
                <Business />
              </RadioLabelWithIcon>
            </Radio>
            <Radio value={InsureeTypeEnum.COMPANY}>
              <RadioLabelWithIcon>
                Právnická osoba
                <Company />
              </RadioLabelWithIcon>
            </Radio>
          </RadioStack>
        </RadioGroup>
        {touched.applicant?.type && <FormErrorMessage>{errors.applicant?.type}</FormErrorMessage>}
      </FormControl>

      <FormSpacer />

      <FormGroup>
        <Flex sx={{ columnGap: 8, rowGap: 2, flexWrap: 'wrap', alignItems: 'start' }}>
          <FormControl
            id="applicant.ino"
            isInvalid={touched.applicant?.ino && !!errors.applicant?.ino}
            isDisabled={extended || formIsDisabled}
          >
            <FormLabel>
              IČO
              <RequiredField />
            </FormLabel>

            <InputGroup
              name="applicant.ino"
              placeholder="Např. 00000000"
              defaultValue={values.ino}
              onChange={(e) => setValues({ ...values, ino: e.target.value })}
            />

            <FormHelperText>Zadejte číslice bez mezer, lomítek či pomlček.</FormHelperText>
            {touched.applicant?.ino && <FormErrorMessage>{errors.applicant?.ino}</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={aresFailed}>
            <FormLabel>&nbsp;</FormLabel>

            <Button
              type="button"
              variant="outline"
              isLoading={aresIsLoading}
              isDisabled={!values.ino || values.ino.length !== 8 || extended || formIsDisabled}
              onClick={fetchAres}
            >
              <Cloud color="secondary.500" />
              Načíst údaje o klientovi
            </Button>
            {aresFailed && <FormErrorMessage>Klient nenalezen.</FormErrorMessage>}
          </FormControl>
        </Flex>
      </FormGroup>

      <FormGroup>
        <Flex sx={{ gap: 2 }} direction="column">
          <FormControl
            id="applicant.companyName"
            isInvalid={touched.applicant?.companyName && !!errors.applicant?.companyName}
            isDisabled={extended || formIsDisabled}
          >
            <FormLabel>
              Název firmy
              <RequiredField />
            </FormLabel>

            <InputGroup
              placeholder="Např. Firma, s.r.o."
              defaultValue={values.companyName}
              onChange={(e) => setValues({ ...values, companyName: e.target.value })}
            />
            {touched.applicant?.companyName && (
              <FormErrorMessage>{errors.applicant?.companyName}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="applicant.vatPayer" isDisabled={extended || formIsDisabled}>
            <Checkbox
              isChecked={values.vatPayer}
              onChange={(e) => setValues && setValues({ ...values, vatPayer: e.target.checked })}
            >
              Plátce DPH
            </Checkbox>
          </FormControl>
        </Flex>
      </FormGroup>

      {extended && (
        <>
          {values.type !== InsureeTypeEnum.COMPANY && (
            <FormGroup>
              <FormControl
                id="applicant.identificationNumber"
                isInvalid={
                  touched.applicant?.identificationNumber &&
                  !!errors.applicant?.identificationNumber
                }
              >
                <FormLabel>
                  RČ
                  <RequiredField />
                </FormLabel>

                <InputGroup
                  name="applicant.identificationNumber"
                  placeholder="Např. 0000000000"
                  onChange={(e) =>
                    setValues({ ...values, identificationNumber: e.target.value.replace('/', '') })
                  }
                  value={values?.identificationNumber}
                />

                {touched.applicant?.identificationNumber && (
                  <FormErrorMessage>{errors.applicant?.identificationNumber}</FormErrorMessage>
                )}
              </FormControl>
            </FormGroup>
          )}

          <FormGroup>
            <FormControl id="applicant.title">
              <FormLabel>Titul před jménem</FormLabel>

              {!useTitlesEnum && (
                <InputGroup
                  placeholder="Např. Ing."
                  htmlSize={8}
                  defaultValue={values.title}
                  onChange={(e) => setValues({ ...values, title: e.target.value })}
                />
              )}

              {useTitlesEnum && (
                <Select
                  value={values.title}
                  onChange={(e) => setValues({ ...values, title: parseInt(e.target.value) })}
                >
                  <option value={''}>Vyberte</option>
                  {(dataUserDegrees?.data.before ?? []).map((degree) => (
                    <option value={degree.id}>{degree.abb}</option>
                  ))}
                </Select>
              )}

            </FormControl>

            <FormControl
              id="applicant.firstName"
              isInvalid={touched.applicant?.firstName && !!errors.applicant?.firstName}
            >
              <FormLabel htmlFor="firstNameId">
                Jméno
                <RequiredField />
              </FormLabel>
              <InputGroup
                id="firstNameId"
                placeholder="Jméno klienta"
                autoComplete="given-name"
                defaultValue={values.firstName}
                onChange={(e) => setValues({ ...values, firstName: e.target.value })}
              />
              {touched.applicant?.firstName && (
                <FormErrorMessage>{errors.applicant?.firstName}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              id="applicant.familyName"
              isInvalid={touched.applicant?.familyName && !!errors.applicant?.familyName}
            >
              <FormLabel htmlFor="lastNameId">
                Příjmení
                <RequiredField />
              </FormLabel>
              <InputGroup
                id="lastNameId"
                placeholder="Příjmení klienta"
                autoComplete="family-name"
                defaultValue={values.familyName}
                onChange={(e) => setValues({ ...values, familyName: e.target.value })}
              />
              {touched.applicant?.familyName && (
                <FormErrorMessage>{errors.applicant?.familyName}</FormErrorMessage>
              )}
            </FormControl>

            {useTitlesEnum && (
              <FormControl id="applicant.title">
                <FormLabel>Titul za jménem</FormLabel>
                <Select
                  value={values.titleAfter}
                  onChange={(e) => setValues({ ...values, titleAfter: parseInt(e.target.value) })}
                >
                  <option value={''}>Vyberte</option>
                  {(dataUserDegrees?.data.after ?? []).map((degree) => (
                    <option value={degree.id}>{degree.abb}</option>
                  ))}
                </Select>
              </FormControl>
            )}
          </FormGroup>
          <FormControl id="applicant.mandatoryPerson" >
            <Checkbox
              isChecked={values.mandatoryPerson}
              onChange={(e) =>
                setValues && setValues({ ...values, mandatoryPerson: e.target.checked })
              }
            >
              Povinná osoba ve smyslu zákona č. 340/2015 Sb., o registru smluv
              <Tooltip
                shouldWrapChildren
                hasArrow
                label={'Povinnou osobou je zejména stát, některé obce, kraje, města nebo jiné právnické osoby, které jsou těmito subjekty zřízeny nebo vlastněny z více než poloviny, a to i prostřednictvím jiných osob. Jde tedy také o některé příspěvkové organizace, s.r.o. nebo třeba akciové společnosti.'}
                placement="top"
              >
                <LabelHintIcon style={{marginLeft: 8}} />
              </Tooltip>
            </Checkbox>
          </FormControl>
        </>
      )}

      <FormSpacer />

      {showAddress && showSkipAddress && (
        <FormControl id="applicant.addressSameAsPlace">
          <Checkbox
            isChecked={values.addressSameAsPlace}
            onChange={(e) =>
              setValues && setValues({ ...values, addressSameAsPlace: e.target.checked })
            }
          >
            Adresa sídla je stejná jako první místo pojištění
          </Checkbox>
        </FormControl>
      )}
      {showAddress && !values.addressSameAsPlace && (
        <>
          <AddressAutocomplete
            onAddressAccepted={(address: IAddressSuggestion) => {
              setValues({
                ...values,
                street: transformAddressToStreet(address),
                streetNumber: transformAddressToStreetNumber(address),
                zip: address.postalCode,
                city: address.city,
                ruianId: address.ruianId,
              })
            }}
            isCompanyHq={true}
          />
          {/*<>*/}
          <FormGroup>
            <FormControl
              id="applicant.street"
              isInvalid={touched.applicant?.street && !!errors.applicant?.street}
            >
              <FormLabel>Ulice</FormLabel>

              <InputGroup
                placeholder="Např. Dlouhá"
                htmlSize={24}
                value={values.street}
                onChange={(e) => setValues({ ...values, street: e.target.value })}
                isDisabled={true}
              />
              {touched.applicant?.street && (
                <FormErrorMessage>{errors.applicant?.street}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              id="applicant.streetNumber"
              isInvalid={touched.applicant?.streetNumber && !!errors.applicant?.streetNumber}
            >
              <FormLabel>Číslo popisné nebo orientační</FormLabel>

              <InputGroup
                isDisabled={true}
                placeholder="Např. 123"
                htmlSize={4}
                value={values.streetNumber}
                onChange={(e) => setValues({ ...values, streetNumber: e.target.value })}
              />
              {touched.applicant?.streetNumber && (
                <FormErrorMessage>{errors.applicant?.streetNumber}</FormErrorMessage>
              )}
            </FormControl>
          </FormGroup>

          <InsureeAddress setValues={setValues} values={values} errors={errors} touched={touched} />
        </>
      )}

      {extended && (
        <>
          <FormSpacer />

          <FormGroup>
            <FormControl
              id="applicant.mobilePhone"
              isInvalid={touched.applicant?.mobilePhone && !!errors.applicant?.mobilePhone}
            >
              <FormLabel>
                Mobilní telefon
                <RequiredField />
              </FormLabel>
              <InputGroup
                inputMode="tel"
                placeholder="Telefonní číslo"
                pattern="[0-9\ ]+"
                defaultValue={values?.mobilePhone}
                onChange={(e) => setValues && setValues({ ...values, mobilePhone: e.target.value })}
              />
              {touched.applicant?.mobilePhone && (
                <FormErrorMessage>{errors.applicant?.mobilePhone}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl id="applicant.phone">
              <FormLabel optional>Pevná linka</FormLabel>
              <InputGroup
                inputMode="tel"
                placeholder="Telefonní číslo"
                pattern="[0-9\ ]+"
                defaultValue={values?.phone}
                onChange={(e) => setValues && setValues({ ...values, phone: e.target.value })}
              />
            </FormControl>

            <FormControl
              id="applicant.email"
              isInvalid={touched.applicant?.email && !!errors.applicant?.email}
            >
              <FormLabel>
                E-mail
                <RequiredField />
              </FormLabel>
              <InputGroup
                placeholder="email"
                defaultValue={values.email}
                onChange={(e) => {
                  setValues && setValues({ ...values, email: e.target.value })
                  onInsureeEmailValue && onInsureeEmailValue(e.target.value)
                }}
              />
              {touched.applicant?.email && (
                <FormErrorMessage>{errors.applicant?.email}</FormErrorMessage>
              )}
            </FormControl>
          </FormGroup>

          {(isGcp()) ? (
            <>
              <Flex sx={{ gap: 2 }}>
                <FormControl id="applicant.communicationConsent">
                  <Checkbox
                    isChecked={values?.communicationConsent}
                    onChange={(e) =>
                      setValues && setValues({ ...values, communicationConsent: e.target.checked })
                    }
                  >
                    Souhlas s el. komunikací při jednání o uzavření pojistné
                  </Checkbox>
                </FormControl>
                <Tooltip
                  shouldWrapChildren
                  hasArrow
                  label="Souhlas s el. komunikací při jednání o uzavření pojistné"
                  placement="top"
                >
                  <Info color="blue.500" />
                </Tooltip>
              </Flex>
              <Flex sx={{ gap: 2 }}>
                <FormControl id="applicant.communicationConsentDuringContractDuration">
                  <Checkbox
                    isChecked={values?.communicationConsentDuringContractDuration}
                    onChange={(e) =>
                      setValues && setValues({ ...values, communicationConsentDuringContractDuration: e.target.checked })
                    }
                  >
                    Souhlas s el. komunikací během trvání pojištění
                  </Checkbox>
                </FormControl>
                <Tooltip
                  shouldWrapChildren
                  hasArrow
                  label="Souhlas s el. komunikací během trvání pojištění"
                  placement="top"
                >
                  <Info color="blue.500" />
                </Tooltip>
              </Flex>
            </>
          ) : (
            <Flex sx={{ gap: 2 }}>
              <FormControl id="applicant.communicationConsent">
                <Checkbox
                  isChecked={values?.communicationConsent}
                  onChange={(e) =>
                    setValues && setValues({ ...values, communicationConsent: e.target.checked })
                  }
                >
                  Souhlas s elektronickou komunikací
                </Checkbox>
              </FormControl>
              <Tooltip
                shouldWrapChildren
                hasArrow
                label="Souhlas s elektronickou komunikací"
                placement="top"
              >
                <Info color="blue.500" />
              </Tooltip>
            </Flex>
          )}
          <FormSpacer />
          <FormGroup>
            <FormControl
              id="applicant.entryFromRegister"
              isInvalid={
                touched.applicant?.entryFromRegister && !!errors.applicant?.entryFromRegister
              }
            >
              <FormLabel
                tooltip={(
                  <>
                    <div>OR – uvést Spisovou značku (vzor: OR/B 2954 vedená u KS v Brně)</div>
                    <div>ŽR – uvést úřad, u kterého je provedena registrace – (vzor: ŽR/Magistrát města Brna)</div>
                  </>
                )}
              >
                Zápis z OR/ŽR
                <RequiredField />
              </FormLabel>
              <InputGroup
                placeholder="Zápis z OR/ŽR"
                defaultValue={values.entryFromRegister}
                onChange={(e) =>
                  setValues && setValues({ ...values, entryFromRegister: e.target.value })
                }
              />
              {touched.applicant?.entryFromRegister && (
                <FormErrorMessage>{errors.applicant?.entryFromRegister}</FormErrorMessage>
              )}
            </FormControl>
          </FormGroup>
        </>
      )}
    </>
  )
}
