import {
  Badge,
  Box,
  Button,
  Flex, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Switch,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Loading } from '../../components/Loading'

import { Add, External, Plus, Trash } from '../../icons'
import { PageToolbar } from '../PageToolbar'
import { OrgInfoSectionHeading } from './FlexiGroupDetail'
import { useIsSupervisorOrAdmin, userRoleToLabel } from '../../model/utils/userUtils'
import { useGetOrganizations } from '../../model/api/organization/useGetOrganizations'
import { IOrganization } from '../../model/interfaces/entities/IOrganization'
import { useIsDevMode, useQueryLimit } from '../../model/hooks/useIsDevMode'
import { MenuItem } from '../../components/Menu'
import { usePostFlexiGroupAddMember } from '../../model/api/flexiGroup/usePostFlexiGroupAddMember'
import { usePostFlexiGroupRemoveMember } from '../../model/api/flexiGroup/usePostFlexiGroupRemoveMember'
import { SearchField } from '../SearchField'
import { ShowInactiveSwitch } from './ShowInactiveSwitch'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { FLEXI_GROUP_LIST_PAGE_LIMIT } from '../../pages/flexiGroup/constants'
import { SwitchControl } from '../../components/SwitchControl'
import { getOrganizationTypTranslated } from '../../model/utils/organizationUtils'
import { OrganizationTypeEnum } from '../../model/enums/OrganizationTypeEnum'

const OrganizationRow = (
  {
    organization,
    notInFlexiGroup,
    flexiGroupId
  }: {
    organization: IOrganization,
    notInFlexiGroup: boolean,
    flexiGroupId: string
  }) => {

  const { mutate: mutateAdd } = usePostFlexiGroupAddMember(() => {}, () => {})
  const { mutate: mutateRemove } = usePostFlexiGroupRemoveMember(() => {}, () => {})

  const addMember = () => {
    mutateAdd({
      flexiGroupId,
      memberId: organization.id
    });
  }

  const removeMember = () => {
    mutateRemove({
      flexiGroupId,
      memberId: organization.id
    });
  }

  return (
    <>
      <Tr>
        <Td>
          <Text sx={{ color: 'primary.500', fontWeight: 'medium' }}>
            <Link to={`/organization/detail/${organization.id}`}>
              {organization.username}
            </Link>
          </Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.500', fontWeight: 'medium' }}>
            {organization.contactPerson}
          </Text>
        </Td>
        <Td>
          <Text sx={{ color: 'gray.500' }}>{organization.city}</Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.700' }}>{organization.description}</Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.700' }}>{organization.role && userRoleToLabel(organization.role)}</Text>
        </Td>
        <Td>
          <Flex sx={{ gap: 1, justifyContent: 'end' }}>
            <Badge colorScheme="blue">{getOrganizationTypTranslated(organization.type as OrganizationTypeEnum) || '-'}</Badge>
          </Flex>
        </Td>
        <Td>
          {notInFlexiGroup ? (
            <Button variant="outline" colorScheme="black" size={'sm'} onClick={() => addMember()}>
              <Add />
              Přidat
            </Button>
          ) : (
            <Button variant="outline" colorScheme="red" size={'sm'} onClick={() => removeMember()}>
              <Trash/>
              Odebrat
            </Button>
          )}
        </Td>
      </Tr>
    </>
  )
}
const ORGANIZATION_USER_LIMIT = 1_000_000

export const FlexiGroupOrganizationList = ({notInFlexiGroup = false}: {notInFlexiGroup?: boolean}) => {
  const { id } = useParams<'id'>() as {id: string};
  const [addModalIsOpened, setAddModalIsOpened] = useState(false);

  const [queryParams, setQueryParams] = useState<IQueryParams>({
    showInactive: 0,
    showAll: 0,
    page: 1,
    search: '',
    limit: FLEXI_GROUP_LIST_PAGE_LIMIT,
  })

  const { data, isError, isLoading, error } = useGetOrganizations({
    page: 1,
    search: queryParams.search,
    limit: ORGANIZATION_USER_LIMIT,
    flexiGroup: id,
    notInFlexiGroup: notInFlexiGroup ? 1 : 0,
    showAll: notInFlexiGroup ? (queryParams.showAll ? 1 : 0) : undefined,
    isActive: !queryParams?.showInactive
  })

  const userIsSupervisor = useIsSupervisorOrAdmin()

  const toastMessage = useToast()

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      {!notInFlexiGroup && (
        <>
          <Modal size={'4xl'} isOpen={addModalIsOpened} onClose={() => setAddModalIsOpened(false)}>
            <ModalOverlay />

            <ModalContent sx={{ mx: 4}}>
              <ModalCloseButton />

              <ModalHeader sx={{ px: 4 }}>Přidat organizaci do flexibilní skupiny</ModalHeader>

              <ModalBody sx={{ px: 2, pt: 0 }}>
                <FlexiGroupOrganizationList notInFlexiGroup={true} />
              </ModalBody>
            </ModalContent>
          </Modal>

          <PageToolbar
            leftElements={<OrgInfoSectionHeading>Organizace ve flexibilní skupině </OrgInfoSectionHeading>}
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <Button
                  hidden={!userIsSupervisor}
                  colorScheme="primary"
                  size="sm"
                  onClick={() => setAddModalIsOpened(true)}
                >
                  <Add />
                  Přidat organizaci do flexibilní skupiny
                </Button>
              </Flex>
            }
          />
        </>
      )}

      {notInFlexiGroup && (
        <Flex sx={{ gap: 4, justifyContent: 'space-between' }}>
          <SearchField
            inputPlaceholder="Hledat dle názvu"
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />

          <ShowInactiveSwitch queryParams={queryParams} setQueryParams={setQueryParams} />
          <SwitchControl>
            <Switch
              size="md"
              id="showAll"
              isChecked={queryParams.showAll === 1}
              onChange={(e) => {
                setQueryParams({ ...queryParams, page: 1, showAll: e.target.checked ? 1 : undefined })
              }}
            />
            <FormLabel htmlFor="showInactive" variant="switch">
              Zobrazit všechny organizace
            </FormLabel>
          </SwitchControl>
        </Flex>
      )}


        <Table variant="striped">
          <Thead>
            <Tr>
              <Td>Organizace</Td>
              <Td>Kontaktní osoba</Td>
              <Td>Obec</Td>
              <Td>Popis</Td>
              <Td></Td>
              <Td></Td>
              <Td width={1}></Td>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {data?.data.items &&
                  data.data.items.length > 0 &&
                  data.data.items.map((organization: IOrganization) => {
                    return <OrganizationRow
                      flexiGroupId={id}
                      notInFlexiGroup={notInFlexiGroup}
                      organization={organization}
                      key={organization.id} />
                  })}
              </>
            )}
          </Tbody>
        </Table>
    </Box>
  )
}
