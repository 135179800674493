import { Heading, HeadingProps } from '@chakra-ui/react'

import { FlexiGroupUserList } from './FlexiGroupUserList'
import { useLocation } from 'react-router-dom'
import { FlexiGroupOrganizationList } from './FlexiGroupOrganizationList'

export const OrgInfoSectionHeading = (props: HeadingProps) => (
  <Heading sx={{ fontSize: 'xl', fontWeight: 'normal' }} {...props} />
)

export const FlexiGroupDetail = () => {
  const location = useLocation()

  return (
    <>
      <FlexiGroupOrganizationList key={`${location.pathname}-children-list`} />
      <FlexiGroupUserList key={`${location.pathname}-user-list`} />
    </>
  )
}
