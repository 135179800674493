import api from '../api'
import { useMutation } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import queryString from 'query-string'

export const useGetOrganizationsExport = (
  downloadOrganizationExportCSV: (data: string) => void,
  isActive?: number,
  search?: string,
) => {
  const query = queryString.stringify({
    isActive,
    search,
    organizationType: 'partner',
  })

  return useMutation(() => api.get<string>(`${ApiRouteEnum.ORGANIZATION_EXPORT}?${query}`), {
    mutationKey: [ApiRouteEnum.ORGANIZATION_EXPORT],
    onSuccess: (data) => {
      downloadOrganizationExportCSV(data.data)
    },
    onError: (error) => {
      console.log(error)
    },
  })
}