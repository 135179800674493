import { UserEditSchema } from '../utils/UserEditValidation'
import { Form, Formik } from 'formik'
import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../components/FormSection'
import { FormGroup } from '../components/FormGroup'
import { Box, Button, FormControl, FormErrorMessage, Grid, Select, Switch } from '@chakra-ui/react'
import { FormLabel } from '../components/FormLabel'
import { IUserType } from '../model/interfaces/entities/IUserType'
import { InputGroup } from '../components/InputGroup'
import { FormSpacer } from '../components/FormSpacer'
import { phonePrefixes } from '../utils/phonePrefixes'
import { IUserRole } from '../model/interfaces/entities/IUserRole'
import { SwitchControl } from '../components/SwitchControl'
import { ArrowLeft } from '../icons'
import { IUserItem } from '../model/interfaces/entities/IUser'
import { useParams } from 'react-router-dom'
import { useGetUserRole } from '../model/api/user/useGetUserRole'
import { useGetUserType } from '../model/api/user/useGetUserType'
import { useGetCompanies } from '../model/api/company/useGetCompany'
import { useGetOrganizationsPagination } from '../model/api/organization/useGetOrganizationPagination'
import { useIsSupervisorOrAdmin, userRoleToLabel } from '../model/utils/userUtils'
import { RequiredField } from './RequiredField'
import { organizationListToSelectOptions } from '../model/utils/organizationUtils'
import { Select as Select2 } from 'chakra-react-select'
import { UserRoleEnum } from '../model/enums/UserRoleEnum'
import { useIsDevMode } from '../model/hooks/useIsDevMode'

export const UserForm: React.FC<{
  initialValues: IUserItem
  onSubmitForm: (values: any) => void
  onGoBack: () => void
  isLoading: boolean
}> = ({ initialValues, onSubmitForm, onGoBack, isLoading }) => {
  const { id } = useParams<'id'>()
  const {
    data: userRole,
    isError: isRoleError,
    error: roleError,
  } = useGetUserRole(initialValues?.parent?.toString())
  const { data: userType, isError: isTypeError, error: typeError } = useGetUserType()
  const { data: dataCompany } = useGetCompanies()
  const { data: organizationsData, isLoading: organizationsIsLoading } =
    useGetOrganizationsPagination(1, useIsDevMode() ? 50 : 1_000_000)
  const userIsSupervisor = useIsSupervisorOrAdmin()

  const organizationSelectOptions = organizationListToSelectOptions(
    organizationsData?.data?.items || [],
  )

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialValues}
      onSubmit={(values) => onSubmitForm(values)}
      validationSchema={UserEditSchema}
    >
      {({ handleChange, errors, touched, values, setFieldValue }) => {
        const isSalesman = values?.role === UserRoleEnum.SALESMAN
        return (
          <Form>
            <FormSection>
              <FormSectionHeader>
                <FormSectionTitle>Informace o uživateli {values.isActive} </FormSectionTitle>

                <FormSectionDescription>Základní informace o uživateli.</FormSectionDescription>
              </FormSectionHeader>

              <FormSectionBody>
                <FormControl isInvalid={!!errors.role && touched.role}>
                  <FormLabel>Role</FormLabel>

                  <Select
                    id="role"
                    name="role"
                    value={values?.role}
                    onBlur={handleChange}
                    onChange={handleChange}
                  >
                    {userRole?.data &&
                      userRole.data.map((option: IUserRole, index) => {
                        return (
                          <option key={index} value={option.code}>
                            {userRoleToLabel(option.code)}
                          </option>
                        )
                      })}
                  </Select>
                  <FormErrorMessage>{errors.role}</FormErrorMessage>
                </FormControl>
                {userIsSupervisor && (
                  <FormGroup>
                    <FormControl>
                      <FormLabel>Organizace</FormLabel>
                      <Select2
                        id="parent"
                        name="parent"
                        onChange={(value) => setFieldValue('parent', value?.value)}
                        placeholder="Vyberte organizaci"
                        options={organizationSelectOptions}
                        value={organizationSelectOptions.find((o) => o.value == values.parent)}
                        isClearable={true}
                        isLoading={organizationsIsLoading}
                        loadingMessage={() => 'Načítám organizace...'}
                      />
                    </FormControl>
                  </FormGroup>
                )}
                <FormGroup sx={{ columnGap: 4, alignItems: 'end' }}>
                  {!isSalesman && (
                    <>
                      <FormControl isInvalid={!!errors.userType && touched.userType}>
                        <FormLabel>Typ uživatele</FormLabel>

                        <Select
                          id="userType"
                          name="userType"
                          onChange={handleChange}
                          value={values.userType}
                        >
                          {userType?.data &&
                            userType.data.map((option: IUserType, index) => {
                              return (
                                <option key={index} value={option.code}>
                                  {option.code}
                                </option>
                              )
                            })}
                        </Select>
                        <FormErrorMessage>{errors.userType}</FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={!!errors.username && touched.username}>
                        <FormLabel htmlFor="username">
                          Uživatelské jméno
                          <RequiredField />
                        </FormLabel>
                        <InputGroup
                          id="username"
                          name="username"
                          placeholder="Uživatelské jméno"
                          autoComplete="username"
                          onChange={handleChange}
                          value={values && values.username}
                        />
                        <FormErrorMessage>{errors.username}</FormErrorMessage>
                      </FormControl>
                    </>
                  )}

                  <FormControl
                    isInvalid={!!errors.usernameOtherSystem && touched.usernameOtherSystem}
                  >
                    <FormLabel htmlFor="usernameOtherSystem">
                      Uživatelské jméno do jiných systémů
                      {isSalesman && <RequiredField />}
                    </FormLabel>
                    <InputGroup
                      id="usernameOtherSystem"
                      name="usernameOtherSystem"
                      placeholder="Uživatelské jméno do jiných systémů"
                      autoComplete="usernameOtherSystem"
                      onChange={handleChange}
                      value={values && values.usernameOtherSystem}
                    />
                    <FormErrorMessage>{errors.usernameOtherSystem}</FormErrorMessage>
                  </FormControl>
                </FormGroup>

                <FormGroup>
                  <FormControl isInvalid={!!errors.firstName && touched.firstName}>
                    <FormLabel>
                      Jméno
                      <RequiredField />
                    </FormLabel>
                    <InputGroup
                      id="firstName"
                      name="firstName"
                      placeholder="Vaše jméno"
                      autoComplete="given-name"
                      onChange={handleChange}
                      value={values && values.firstName}
                    />
                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.lastName && touched.lastName}>
                    <FormLabel>
                      Příjmení
                      <RequiredField />
                    </FormLabel>
                    <InputGroup
                      id="lastName"
                      name="lastName"
                      placeholder="Vaše příjmení"
                      autoComplete="given-name"
                      onChange={handleChange}
                      value={values && values.lastName}
                    />
                    <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                  </FormControl>
                </FormGroup>

                {!isSalesman && (
                  <>
                    <FormSpacer />

                    <FormGroup>
                      <FormControl isInvalid={!!errors.street && touched.street}>
                        <FormLabel>Ulice</FormLabel>

                        <InputGroup
                          id="street"
                          name="street"
                          placeholder="Např. Dlouhá"
                          htmlSize={24}
                          onChange={handleChange}
                          value={values && values.street}
                        />
                        <FormErrorMessage>{errors.street}</FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={!!errors.streetNumber && touched.streetNumber}>
                        <FormLabel>Číslo popisné nebo orientační</FormLabel>

                        <InputGroup
                          id="streetNumber"
                          name="streetNumber"
                          placeholder="Např. 123"
                          htmlSize={4}
                          onChange={handleChange}
                          value={values && values.streetNumber}
                        />
                        <FormErrorMessage>{errors.streetNumber}</FormErrorMessage>
                      </FormControl>
                    </FormGroup>

                    <FormGroup>
                      <FormControl isInvalid={!!errors.zip && touched.zip}>
                        <FormLabel htmlFor="zip" tooltip="PSČ">
                          PSČ
                        </FormLabel>
                        <InputGroup
                          id="zip"
                          name="zip"
                          placeholder="00000"
                          autoComplete="postal-code"
                          htmlSize={4}
                          onChange={handleChange}
                          value={values && values.zip}
                        />
                        <FormErrorMessage>{errors.zip}</FormErrorMessage>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Stát</FormLabel>

                        <Select
                          id="country"
                          name="country"
                          defaultValue="CZ"
                          onChange={handleChange}
                        >
                          <option value="CZ">Česko</option>
                        </Select>
                      </FormControl>

                      <FormControl isInvalid={!!errors.city && touched.city}>
                        <FormLabel>Město</FormLabel>

                        <InputGroup
                          id="city"
                          name="city"
                          placeholder="Vyberte obec"
                          autoComplete="city"
                          onChange={handleChange}
                          value={values && values.city}
                        />
                        <FormErrorMessage>{errors.city}</FormErrorMessage>
                      </FormControl>
                    </FormGroup>

                    <FormSpacer />

                    <FormGroup>
                      <FormControl>
                        <FormLabel>Mobilní telefon</FormLabel>
                        <Box
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: 'auto auto',
                            placeContent: 'start',
                            placeItems: 'start',
                            gap: 2,
                          }}
                        >
                          <FormControl>
                            <Select
                              id="mobilePhonePrefix"
                              name="mobilePhonePrefix"
                              onChange={handleChange}
                            >
                              {phonePrefixes &&
                                phonePrefixes.map((prefix) => {
                                  return (
                                    <option key={prefix} value={prefix}>
                                      {prefix}
                                    </option>
                                  )
                                })}
                            </Select>
                          </FormControl>

                          <FormControl isInvalid={!!errors.phone && touched.phone}>
                            <InputGroup
                              name="phone"
                              inputMode="tel"
                              placeholder="Telefonní číslo"
                              htmlSize={10}
                              onChange={handleChange}
                              value={values && values.phone}
                            />
                          </FormControl>
                          <FormErrorMessage>{errors.phone}</FormErrorMessage>
                        </Box>
                      </FormControl>

                      <FormControl isInvalid={!!errors.email && touched.email}>
                        <FormLabel>
                          E-mail
                          <RequiredField />
                        </FormLabel>

                        <InputGroup
                          name="email"
                          type="email"
                          onChange={handleChange}
                          value={values && values.email}
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>
                    </FormGroup>

                    <FormSpacer />

                    <FormGroup>
                      <FormControl>
                        <FormLabel>Aktivní</FormLabel>

                        <SwitchControl>
                          <FormLabel htmlFor="active" variant="switch">
                            Ne
                          </FormLabel>
                          <Switch
                            id="isActive"
                            name="isActive"
                            size="md"
                            isChecked={values?.isActive}
                            onBlur={handleChange}
                            onChange={handleChange}
                          />
                          <FormLabel htmlFor="active" variant="switch">
                            Ano
                          </FormLabel>
                        </SwitchControl>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Pojištění povoleno</FormLabel>

                        <SwitchControl>
                          <FormLabel htmlFor="insuranceAllowed" variant="switch">
                            Ne
                          </FormLabel>
                          <Switch
                            id="isAllowedInsurance"
                            name="isAllowedInsurance"
                            size="md"
                            onChange={handleChange}
                            isChecked={values.isAllowedInsurance}
                          />
                          <FormLabel htmlFor="insuranceAllowed" variant="switch">
                            Ano
                          </FormLabel>
                        </SwitchControl>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Sjednání za jiného uživatele</FormLabel>

                        <SwitchControl>
                          <FormLabel htmlFor="isAllowedInsuranceAnotherUser" variant="switch">
                            Ne
                          </FormLabel>
                          <Switch
                            id="isAllowedInsuranceAnotherUser"
                            name="isAllowedInsuranceAnotherUser"
                            size="md"
                            onChange={handleChange}
                            isChecked={!!values.isAllowedInsuranceAnotherUser}
                          />
                          <FormLabel htmlFor="isAllowedInsuranceAnotherUser" variant="switch">
                            Ano
                          </FormLabel>
                        </SwitchControl>
                      </FormControl>
                    </FormGroup>
                  </>
                )}
              </FormSectionBody>
            </FormSection>

            {!isSalesman && dataCompany?.data && dataCompany?.data.length > 0 && (
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Osobní čísla</FormSectionTitle>

                  <FormSectionDescription>
                    Zadejte osobní čísla uživatele u jednotlivých pojišťoven.
                  </FormSectionDescription>
                </FormSectionHeader>

                <FormSectionBody>
                  <Box
                    sx={{
                      display: 'grid',
                      gridAutoFlow: 'reverse',
                      gridTemplateColumns: { base: '1fr', md: '1fr 1fr' },
                      gridTemplateRows: `repeat(${Math.round(dataCompany?.data.length / 2)}, auto)`,
                      columnGap: 16,
                      rowGap: 4,
                    }}
                  >
                    {values.personalNumbers.map((personalNumber, personalNumberIndex) => {
                      return (
                        <FormControl key={personalNumberIndex}>
                          <FormLabel htmlFor={`personalNumbers[${personalNumberIndex}].number`}>
                            {personalNumber.company.name}
                          </FormLabel>
                          <InputGroup
                            id={`personalNumbers[${personalNumberIndex}].number`}
                            name={`personalNumbers[${personalNumberIndex}].number`}
                            onChange={handleChange}
                            defaultValue={personalNumber.number}
                          />
                        </FormControl>
                      )
                    })}
                  </Box>
                </FormSectionBody>
              </FormSection>
            )}

            <Grid
              sx={{
                width: '100%',
                gridTemplateColumns: 'auto auto',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button variant="ghost" isLoading={isLoading} type="button" onClick={onGoBack}>
                <ArrowLeft color="secondary.500" />
                Zpět
              </Button>
              <Button colorScheme="primary" isLoading={isLoading} type="submit">
                Uložit
              </Button>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
