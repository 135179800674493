import { Box, Button, FormErrorMessage, Radio, RadioGroup, useToast } from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { MainContent } from '../../components/MainContent'
import { FormButtons } from '../FormButtons'
import { Business, Company, Duplicate, Save } from '../../icons'
import { AdditionalInsureeSmeInfo } from './AdditionalInsureeSmeInfo'
import { Formik } from 'formik'
import { IApplicant, IBusiness, InsuranceValues } from '../../data/InsuranceForm'
import { useParams, useNavigate } from 'react-router-dom'
import { ApplicantSchemaWithDetails } from '../../model/yupSchemas/Step1FormSchema'
import * as Yup from 'yup'
import { usePostStep4 } from '../../model/api/insuranceForm/usePostStep4'
import { submitHandler, validateAndScroll } from '../../model/utils/formHelpers'
import { usePatchStep4 } from '../../model/api/insuranceForm/usePatchStep4'
import { FormSubmitEnum } from '../../model/enums/FormSubmitEnum'
import { ValuesWithSubmitter } from '../../model/types/ValuesWithSubmitter'
import { useCustomCopyOffer } from '../../model/hooks/useCustomCopyOffer'
import useCloneClickHandler from '../../model/hooks/useCloneClickHandler'
import React from 'react'
import { InsuredBranches } from './InsuredBranches'
import { ICompanyV2 } from '../../model/interfaces/entities/ICompany'
import { compareObjects } from '../../utils/object'
import { IItem } from '../../model/interfaces/entities/IItem'

interface Step4FormValues {
  applicant: IApplicant,
  item?: IItem
}

interface Step4FormProps {
  initialValues: Step4FormValues
  onSubmit: Function
  viewOnly?: boolean
  onInsureeEmailValue: (email: string) => void
}

export const Step4Form = ({
  initialValues,
  onSubmit,
  viewOnly,
  onInsureeEmailValue,
}: Step4FormProps) => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const toastMessage = useToast()
  const validationSchema = Yup.object({
    applicant: ApplicantSchemaWithDetails,
  })

  const { mutateCopyOffer, isCopyingOffer } = useCustomCopyOffer(id)
  const handleCloneClick = useCloneClickHandler(mutateCopyOffer)


  const checkPricePerYearChanged = (data: any) => {
    if (data.item.pricePerYearChanged) {
      toastMessage({
        description: data.item.pricePerYearChangedWarning,
        status: 'warning',
        isClosable: true,
        position: 'top',
        duration: 7000
      })
    }
  }

  const successPatchCallback = (data: any) => {
    toastMessage({
      title: 'Uloženo',
      description: 'Změny byly uloženy',
      status: 'success',
      isClosable: true,
      position: 'top',
    })
    checkPricePerYearChanged(data)
  }

  const successCallback = (data: any) => {
    onSubmit()
    checkPricePerYearChanged(data)
  }

  const errorCallback = (error: any) => {
    const errorMessage = error?.response?.data?.error || 'Nepodařilo se uložit'

    toastMessage({
      title: 'Chyba',
      description: errorMessage,
      status: 'error',
      isClosable: true,
      position: 'top',
    })
  }

  const { mutate, isLoading } = usePostStep4(successCallback, errorCallback, id)
  const { mutate: patchMutate, isLoading: patchIsLoading } = usePatchStep4(
    successPatchCallback,
    errorCallback,
    id,
  )

  const submitting: boolean = isLoading || patchIsLoading

  const onSubmitForm = (values: ValuesWithSubmitter<Step4FormValues>) => {
    const submitter = values[FormSubmitEnum.SUBMITTER_FIELD]
    delete values[FormSubmitEnum.SUBMITTER_FIELD]
    values.applicant.identificationNumber = values.applicant.identificationNumber.replace('/', '')
    // @ts-ignore
    values.applicant.otherFields = values.business.otherFields.map(otherField => otherField.id ?? otherField.value);

    if (typeof values.applicant.title === 'string') {
      // @ts-ignore
      values.applicant.title = null
    }

    if (submitter === FormSubmitEnum.SUBMIT_AND_SAVE) {
      return patchMutate(values.applicant)
    }

    mutate(values.applicant)
  }

  const hasOtherFields = (values: any) => {
    try {
      return values.insurance.companies.find((company: ICompanyV2) => company.id === values.item.company.id).hasOtherFields
    } catch (e) {
      return false;
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={validationSchema}
    >
      {({
        errors,
        touched,
        values,
        setValues,
        handleChange,
        validateForm,
        handleSubmit,
        setFieldValue,
      }) => {

        const hasNoChanges = compareObjects(initialValues, values).length === 0;
        // @ts-ignore
        const canNextWithoutSave = hasNoChanges && initialValues.step >= 4;

        return (
        <form onSubmit={(e) => submitHandler(e, handleSubmit, setFieldValue)}>
          <MainContent>
            <FormSection>
              <FormSectionHeader>
                <FormSectionTitle>Doplňující informace o pojistníkovi</FormSectionTitle>

                <FormSectionDescription>
                  Doplňte informace o pojistníkovi a dalších osobách pro sjednání pojištění.
                </FormSectionDescription>
              </FormSectionHeader>

              <FormSectionBody>
                <AdditionalInsureeSmeInfo
                  values={{
                    ...values.applicant,
                    identificationNumber:
                      values.applicant.identificationNumber !== null
                        ? values.applicant.identificationNumber.replace('/', '')
                        : '',
                  }}
                  setValues={(applicantValues: IApplicant) =>
                    setValues({
                      ...values,
                      applicant: applicantValues
                    })
                  }
                  itemValues={values.item}
                  touched={touched}
                  errors={errors}
                  extended={true}
                  showAddress={true}
                  onInsureeEmailValue={onInsureeEmailValue}
                  useTitlesEnum={true}
                />
              </FormSectionBody>
            </FormSection>

            {hasOtherFields(values) && (
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Pojištěné obory</FormSectionTitle>

                  <FormSectionDescription>
                    Vyberte všechny činnosti nebo profese, které chce klient pojistit. Doplnění dalších činností/profesí může mít vliv na cenu pojištění.
                  </FormSectionDescription>
                </FormSectionHeader>

                <FormSectionBody>
                  <InsuredBranches
                    values={values}
                    setValues={(values: any) =>
                      setValues(values)
                    }
                  />
                </FormSectionBody>
              </FormSection>
            )}


            <FormButtons
              leftButtons={
                <Button
                  isLoading={isCopyingOffer}
                  variant="outline"
                  size="sm"
                  type="button"
                  onMouseDown={handleCloneClick}
                >
                  <Duplicate color="secondary.500" />
                  Klonovat
                </Button>
              }
              rightButtons={
                !viewOnly ? (
                  <>
                    <Button
                      variant="outline"
                      disabled={submitting}
                      type="submit"
                      name={FormSubmitEnum.SUBMIT_AND_SAVE}
                      isLoading={submitting}
                    >
                      Uložit
                    </Button>
                    {canNextWithoutSave ? (
                      <Button
                        colorScheme="primary"
                        isLoading={submitting}
                        isDisabled={submitting}
                        loadingText="Ukládám..."
                        type="submit"
                        onClick={() => navigate(`/sme/wizard/edit/${id}/5`)}
                      >
                        Pokračovat
                      </Button>
                    ) : (
                      <Button
                        colorScheme="primary"
                        isLoading={submitting}
                        isDisabled={submitting}
                        loadingText="Ukládám..."
                        type="submit"
                        onClick={() => {
                          validateAndScroll(values, validateForm, toastMessage)
                        }}
                      >
                        <Save />
                        Uložit a pokračovat na shrnutí
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    colorScheme="primary"
                    onClick={() => navigate(`/sme/wizard/${id}/completed`)}
                  >
                    Pokračovat
                  </Button>
                )
              }
            />
          </MainContent>
        </form>
      )
    }}
    </Formik>
  )
}
