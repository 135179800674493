import { Button, Flex, Heading } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { Add } from '../../icons'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { FLEXI_GROUP_LIST_PAGE_LIMIT } from './constants'
import { useIsSupervisor } from '../../model/utils/userUtils'
import { FlexiGroupList } from '../../containers/flexiGroup/FlexiGroupList'

export const FlexiGroupPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [queryParams, setRawQueryParams] = useState<IQueryParams>({
    showInactive: searchParams.get('showInactive') === '1' ? 1 : undefined,
    page: searchParams.get('page') ? parseInt(searchParams.get('page')!) : 1,
    search: searchParams.get('search') ? searchParams.get('search')! : undefined,
    limit: FLEXI_GROUP_LIST_PAGE_LIMIT,
  })

  const setQueryParams = (params: IQueryParams) => {
    const newSearchParams: { [key in string]: string } = {
      ...(typeof params.showInactive === 'undefined' ? {} : { showInactive: '1' }),
      ...(typeof params.page !== 'undefined' && params.page !== 1
        ? { page: String(params.page) }
        : {}),
      ...(typeof params.search !== 'undefined' && params.search !== ''
        ? { search: String(params.search) }
        : {}),
    }

    setSearchParams(newSearchParams)
    setRawQueryParams(params)
  }

  const navigate = useNavigate()
  const isSupervisor = useIsSupervisor()

  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Přehled flexibilních skupin</Heading>
              </Flex>
            }
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <Button
                  hidden={!isSupervisor}
                  colorScheme="primary"
                  onClick={() => navigate('/flexi/new')}
                >
                  <Add />
                  Vytvořit novou flexibilní skupinu
                </Button>
              </Flex>
            }
          />

          <FlexiGroupList queryParams={queryParams} setQueryParams={setQueryParams} />
        </PageContent>
      </Container>
    </Section>
  )
}
