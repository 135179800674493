import {
  Badge,
  Box,
  Button,
  Flex, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Switch,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Loading } from '../../components/Loading'
import { getUserStatus, getUserType } from '../../data/profile'

import { Add, Plus, Trash } from '../../icons'
import { useGetUser } from '../../model/api/user/useGetUser'
import { IUser, IUserItem } from '../../model/interfaces/entities/IUser'
import { PageToolbar } from '../PageToolbar'
import { OrgInfoSectionHeading } from './FlexiGroupDetail'
import { useIsSupervisorOrAdmin } from '../../model/utils/userUtils'
import { useIsDevMode } from '../../model/hooks/useIsDevMode'
import { IOrganization } from '../../model/interfaces/entities/IOrganization'
import { usePostFlexiGroupAddMember } from '../../model/api/flexiGroup/usePostFlexiGroupAddMember'
import { usePostFlexiGroupRemoveMember } from '../../model/api/flexiGroup/usePostFlexiGroupRemoveMember'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { FLEXI_GROUP_LIST_PAGE_LIMIT } from '../../pages/flexiGroup/constants'
import { SearchField } from '../SearchField'
import { ShowInactiveSwitch } from './ShowInactiveSwitch'
import { SwitchControl } from '../../components/SwitchControl'

const UserRow = (
  {
    user,
    notInFlexiGroup,
    flexiGroupId
  }: {
    user: IUserItem,
    notInFlexiGroup: boolean,
    flexiGroupId: string
  }) => {

  const { mutate: mutateAdd } = usePostFlexiGroupAddMember(() => {}, () => {})
  const { mutate: mutateRemove } = usePostFlexiGroupRemoveMember(() => {}, () => {})

  const addMember = () => {
    mutateAdd({
      flexiGroupId,
      memberId: user.id
    });
  }

  const removeMember = () => {
    mutateRemove({
      flexiGroupId,
      memberId: user.id
    });
  }

  const { colorScheme: userTypeColorScheme, label: userTypeLabel } = getUserType(user.userType)
  return (
    <>
      <Tr>
        <Td>
          <Text sx={{ color: 'primary.500', fontWeight: 'medium' }}>
            <Link to={`/user/detail/${user.id}`}>
              {user.lastName} {user.firstName}
            </Link>
          </Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.500', fontWeight: 'medium' }}>
            Organizace
          </Text>
        </Td>
        <Td>
          <Text sx={{ color: 'gray.500' }}>{user.username}</Text>
        </Td>
        <Td>
          <Text sx={{ color: 'primary.700' }}>{user.email}</Text>
        </Td>
        <Td>
          <Flex sx={{ gap: 1, justifyContent: 'end' }}>
            {user.userType && <Badge colorScheme={userTypeColorScheme}>{userTypeLabel}</Badge>}
          </Flex>
        </Td>
        <Td>
          {notInFlexiGroup ? (
            <Button variant="outline" colorScheme="black" size={'sm'} onClick={() => addMember()}>
              <Add />
              Přidat
            </Button>
          ) : (
            <Button variant="outline" colorScheme="red" size={'sm'} onClick={() => removeMember()}>
              <Trash/>
              Odebrat
            </Button>
          )}
        </Td>
      </Tr>
    </>
  )
}

const ORGANIZATION_USER_LIMIT = 1_000_000

export const FlexiGroupUserList = ({notInFlexiGroup = false}: {notInFlexiGroup?: boolean}) => {
  const { id } = useParams<'id'>() as {id: string};
  const [addModalIsOpened, setAddModalIsOpened] = useState(false);

  const [queryParams, setQueryParams] = useState<IQueryParams>({
    page: 1,
    search: '',
    limit: FLEXI_GROUP_LIST_PAGE_LIMIT,
  })

  const navigate = useNavigate()
  const { data, isError, isLoading, error } = useGetUser({
    page: 1,
    search: queryParams.search,
    limit: useIsDevMode() ? 50 : ORGANIZATION_USER_LIMIT,
    flexiGroup: id,
    notInFlexiGroup: notInFlexiGroup ? 1 : 0
  })

  const userIsSupervisor = useIsSupervisorOrAdmin()

  const toastMessage = useToast()

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      {!notInFlexiGroup && (
        <>
          <Modal size={'6xl'} isOpen={addModalIsOpened} onClose={() => setAddModalIsOpened(false)}>
            <ModalOverlay />

            <ModalContent sx={{ mx: 4}}>
              <ModalCloseButton />

              <ModalHeader sx={{ px: 4 }}>Přidat uživatele do flexibilní skupiny</ModalHeader>

              <ModalBody sx={{ px: 2, pt: 0 }}>
                <FlexiGroupUserList notInFlexiGroup={true} />
              </ModalBody>
            </ModalContent>
          </Modal>

          <PageToolbar
            leftElements={<OrgInfoSectionHeading>Uživatelé ve flexibilní skupině </OrgInfoSectionHeading>}
            rightElements={
              <Flex sx={{ gap: 4 }}>
                <Button
                  hidden={!userIsSupervisor}
                  colorScheme="primary"
                  size="sm"
                  onClick={() => setAddModalIsOpened(true)}
                >
                  <Add />
                  Přidat uživatele do flexibilní skupiny
                </Button>
              </Flex>
            }
          />
        </>
      )}

      {notInFlexiGroup && (
        <Flex sx={{ gap: 4, justifyContent: 'space-between' }}>
          <SearchField
            inputPlaceholder="Hledat dle názvu"
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        </Flex>
      )}

        <Table variant="striped">
          <Thead>
            <Tr>
              <Td>Jméno</Td>
              <Td>Organizace</Td>
              <Td>Uživatelské jméno</Td>
              <Td>E-mail</Td>
              <Td></Td>
              <Td width={1}></Td>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {data?.data.items &&
                  data.data.items.length > 0 &&
                  data.data.items.map((user: IUserItem) => {
                    return <UserRow
                      flexiGroupId={id}
                      notInFlexiGroup={notInFlexiGroup}
                      user={user}
                      key={user.id} />
                  })}
              </>
            )}
          </Tbody>
        </Table>

    </Box>
  )
}
