import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePostRemoveFlexiGroup = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({flexiGroupId, memberId}: {flexiGroupId: string, memberId: any}) =>
      api.patch(ApiRouteEnum.USER_FLEXI_REMOVE , {
        user: parseInt(memberId),
        flexiGroup: parseInt(flexiGroupId)
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([ApiRouteEnum.FLEXI_LIST])
        queryClient.invalidateQueries([ApiRouteEnum.USER])
        successCallBack(data.data)
      },
      onError: (error) => errorCallBack(error),
    },
  )
}
