import {
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Loading } from '../../components/Loading'

import { Pagination } from '../../components/Pagination'
import { SearchField } from '../../containers/SearchField'
import { IQueryParams } from '../../model/interfaces/entities/IQueryParams'
import { ORGANIZATION_LIST_PAGE_LIMIT } from '../../pages/organization/constants'
import { calculateTotalPages } from '../../utils/calculateTotalPages'
import { useGetFlexiGroupPagination } from '../../model/api/flexiGroup/useGetFlexiGroupPagination'
import { FLEXI_GROUP_LIST_PAGE_LIMIT } from '../../pages/flexiGroup/constants'
import { ShowInactiveSwitch } from './ShowInactiveSwitch'

export const FlexiGroupList = ({
  queryParams,
  setQueryParams,
}: {
  queryParams: IQueryParams
  setQueryParams: Function
}) => {
  const toastMessage = useToast()
  const { data, isLoading, isError, error, refetch } = useGetFlexiGroupPagination(
    queryParams?.page,
    FLEXI_GROUP_LIST_PAGE_LIMIT,
    queryParams?.showInactive ? undefined : 1,
    queryParams?.search,
  )

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Error',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  useEffect(() => {
    refetch()
  }, [queryParams])

  const [startPage, setStartPage] = useState<number>(1)
  const [endPage, setEndPage] = useState<number>(10)

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box sx={{ display: 'grid', gap: 4 }}>
      <Flex sx={{ gap: 4, justifyContent: 'space-between' }}>
        <SearchField
          inputPlaceholder="Hledat dle názvu"
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />

        <ShowInactiveSwitch queryParams={queryParams} setQueryParams={setQueryParams} />
      </Flex>

      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Název</Th>
            <Th>Popis</Th>
            <Th></Th>
          </Tr>
        </Thead>

        <Tbody>
          {data?.data &&
            data?.data.length > 0 &&
            data.data.map((flexiGroup, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    <Link to={`/flexi/${flexiGroup.id}`}>
                      <Text sx={{ color: 'primary.400', fontWeight: 'medium' }}>
                        {flexiGroup.name || '-'}
                      </Text>
                    </Link>
                  </Td>
                  <Td>
                    <Text sx={{ color: 'gray.600', fontSize: 'sm' }}>
                      {flexiGroup.description || '-'}
                    </Text>
                  </Td>
                  <Td>
                    <Flex sx={{ flexWrap: 'wrap', gap: 1, justifyContent: 'end' }}>
                      {flexiGroup.active && <Badge colorScheme="green">Aktivní</Badge>}
                      {!flexiGroup.active && <Badge colorScheme="orange">Neaktivní</Badge>}
                    </Flex>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>

        <Tfoot>
          <Tr>
            <Td colSpan={6}>
              <Flex sx={{ placeContent: 'center' }}>
                <Pagination
                  totalPages={calculateTotalPages(data?.data.length, ORGANIZATION_LIST_PAGE_LIMIT)}
                  currentPage={queryParams?.page || 1}
                  queryParams={queryParams}
                  setQueryParams={setQueryParams}
                  startPage={startPage}
                  endPage={endPage}
                  onSetStartPage={setStartPage}
                  onSetEndPage={setEndPage}
                  hide={true}
                />
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  )
}
