import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  FormControl, FormLabel,
  Grid,
  Image, Select,
  Spinner,
  useToast,
  useToken,
} from '@chakra-ui/react'

import { Section } from '../../components/Section'
import { Card, CardBody, CardTitle } from '../../components/Card'
import smeDashboardImage from '../../assets/images/sme-dashboard-image.jpg'
import { NewsList } from '../../containers/news/NewsList'
import { fadeInLeft } from '../../utils/fadeInLeft'
import React, { useEffect, useState } from 'react'
import { useGetNews } from '../../model/api/news/useGetNews'
import { conceptsProperties } from '../../data/concepts'
import { ConceptsList } from '../../containers/concepts'
import { ContractsList } from '../../containers/contracts'
import { contractsProperties } from '../../data/contracts'
import { useGetContracts } from '../../model/api/contracts/useGetContracts'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import { formatPrice } from '../../utils/formatPrice'
import { CustomTooltip, PriceTooltip } from '../../containers/Chart'
import { useGetProductionGraph } from '../../model/api/graph/useGetProductionGraph'
import { useGetProductionGraphFilters } from '../../model/api/graph/useGetProductionGraphFilters'
import { Datepicker } from '../../components/Datepicker'
import moment from 'moment'
import { Search } from '../../icons'
import { Select as Select2 } from 'chakra-react-select'
import { useGetOrganizationsPagination } from '../../model/api/organization/useGetOrganizationPagination'
import { useIsSupervisorOrAdmin, usersListToSelectOptions } from '../../model/utils/userUtils'
import { organizationListToSelectOptions } from '../../model/utils/organizationUtils'
import { useGetUser } from '../../model/api/user/useGetUser'
import { useIsDevMode } from '../../model/hooks/useIsDevMode'

export const DashboardPage = () => {
  const [numberOfConcepts, setNumberOfConcepts] = useState(0)
  const [numberOfContracts, setNumberOfContracts] = useState(0)
  const { data, error, isError, isLoading } = useGetNews({
    limit: 3,
    page: 1,
  })

  const numberOfConceptsLimit = 5;
  const numberOfContractsLimit = 5;

  const toastMessage = useToast()
  const handleNumberOfConcepts = (value: number) => {
    setNumberOfConcepts(value)
  }
  const handleNumberOfContracts = (value: number) => {
    setNumberOfContracts(value)
  }

  const { data: dataContracts, isLoading: isLoadingContracts } = useGetContracts({
    hasAniversary: new Date().toJSON().slice(0, 10),
    page: 1,
    limit: numberOfContractsLimit,
  })

  const primaryColor = useToken('colors', 'primary.500')
  const [prodChartFilterValues, setProdChartFilterValues] = useState({
    from: moment().subtract(1, 'year').toDate(),
    to: moment().toDate(),
    insuranceCompanyId: '',
    insuranceTypeName: '',
    organizationId: '',
    userId: ''
  });
  const [prodChartFilteredValues, setProdChartFilteredValues] = useState({
    from: moment().subtract(1, 'year').toDate(),
    to: moment().toDate(),
    insuranceCompanyId: '',
    insuranceTypeName: '',
    organizationId: '',
    userId: ''
  });
  const { data: productionChartData, isLoading: isLoadingProductionChart } = useGetProductionGraph({
    ...prodChartFilteredValues,
    from: prodChartFilteredValues.from && moment(prodChartFilteredValues.from).toISOString(),
    to: prodChartFilteredValues.to && moment(prodChartFilteredValues.to).toISOString(),
  })
  const { data: productionChartFilters, isLoading: isLoadingProductionFilters } = useGetProductionGraphFilters({})
  const [prodChartFilterOpened, setProdChartFilterOpened] = useState(false);

  const setFilterValue = (name: keyof typeof prodChartFilterValues, value: any) => {
    setProdChartFilterValues((prevValues) => ({
      ...prevValues,
      [name]: value === 0 ? undefined : value,
    }));
  }

  const getFilterValue = (name: keyof typeof prodChartFilterValues): any => {
    return prodChartFilterValues[name];
  }

  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Error',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  const insuranceCompanies = Object.values(productionChartFilters?.data?.insuranceCompanies ?? {});

  const isDev = useIsDevMode();

  const { data: organizationsData, isLoading: organizationsIsLoading } =
    useGetOrganizationsPagination(1, isDev ? 50 :  1_000_000)

  const organizationSelectOptions = organizationListToSelectOptions(organizationsData?.data?.items || [])

  const { data: users, isLoading: isLoadingUsers } =
    useGetUser({page: 1, limit: (isDev ? 50 : 1_000_000)})
  const userSelectOptions = usersListToSelectOptions(users?.data?.items || [])

  return (
    <Section>
      <Container>
        <Grid sx={{ gap: 8 }}>
          <Grid
            className="dashboard-header"
            sx={{
              gridTemplateColumns: { base: '1fr', xl: '1fr 2fr' },
              gap: 4,
              justifyContent: 'space-around',
            }}
          >
            <Card sx={{ order: { base: 1, xl: 0 } }}>
              <CardTitle>
                Aktuality
                {data && <Badge>{data?.data.count}</Badge>}
              </CardTitle>

              <CardBody sx={{ p: 0 }}>
                {isLoading ? <Spinner /> : <NewsList news={data?.data.items} />}
              </CardBody>
            </Card>

            <Box sx={{ display: 'grid', placeContent: 'center', placeItems: 'center' }}>
              <Image
                sx={{
                  order: { base: 0, xl: 1 },
                  animation: `${fadeInLeft} 0.5s 1s`,
                  animationFillMode: 'backwards',
                }}
                width={453}
                src={smeDashboardImage}
                alt=""
                aria-hidden
                style={{ mixBlendMode: 'multiply' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Card sx={{ order: { base: 1, xl: 0 } }}>
          <CardTitle>
            Neuzavřené nabídky
            <Badge>{Math.min(numberOfConceptsLimit, numberOfConcepts)}/{numberOfConcepts}</Badge>
          </CardTitle>

          <CardBody sx={{ p: 0 }}>
            <ConceptsList
              concepts={conceptsProperties}
              onNumberOfConcepts={handleNumberOfConcepts}
              params={{ page: 1, limit: numberOfConceptsLimit }}
            />
          </CardBody>
        </Card>
      </Container>
      <Container>
        <Card sx={{ order: { base: 1, xl: 0 } }}>
          <CardTitle>
            Blížící se výročí smluv
            <Badge>{Math.min(numberOfContractsLimit, numberOfContracts)}/{numberOfContracts}</Badge>
          </CardTitle>
          <CardBody sx={{ p: 0 }}>
            {isLoadingContracts ? (
              <Spinner />
            ) : (
              <ContractsList
                contracts={contractsProperties}
                items={dataContracts?.data}
                onNumberOfContracts={handleNumberOfContracts}
              />
            )}
          </CardBody>
        </Card>
      </Container>
      <Container>
        <Card sx={{ order: { base: 1, xl: 0 } }}>
          <CardTitle justifyContent={'space-between'}>
            Graf produkce
            <Button onClick={() => {
              setProdChartFilterOpened(!prodChartFilterOpened);
            }}>Filtrovat</Button>
          </CardTitle>
          <CardBody sx={{ p: 0 }}>
              <div style={{padding: 'var(--ok-space-8)'}}>
                {prodChartFilterOpened && (
                  <div>
                    <Flex sx={{ columnGap: 8, rowGap: 2, flexWrap: 'wrap', alignItems: 'start', marginBottom: '2rem' }}>
                      <FormControl>
                        <FormLabel htmlFor="dateFrom">Datum od</FormLabel>

                        <Datepicker
                          date={getFilterValue('from')}
                          onDateChange={(date: Date) => setFilterValue('from', date)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="dateTo">Datum do</FormLabel>

                        <Datepicker
                          date={getFilterValue('to')}
                          onDateChange={(date: Date) => setFilterValue('to', date)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="insuranceCompanies">Pojišťovna</FormLabel>

                        <Select
                          name={'insuranceCompany'}
                          onChange={(e) => setFilterValue('insuranceCompanyId', e.target.value)}
                          value={getFilterValue('insuranceCompanyId')}
                        >
                          <option value={''}>Všechny pojišťovny</option>
                          {insuranceCompanies.map((option: any) => (
                            <option value={option.id}>{option.label}</option>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="organisation">Organizace</FormLabel>

                        <Select2
                          id="organizationId"
                          name="organizationId"
                          onChange={(value) => setFilterValue('organizationId', value?.value)}
                          placeholder="Vyberte organizaci"
                          options={organizationSelectOptions}
                          value={organizationSelectOptions.find((o) => o.value == getFilterValue('organizationId'))}
                          isClearable={true}
                          isLoading={organizationsIsLoading}
                          loadingMessage={() => 'Načítám organizace...'}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="users">Uživatelé</FormLabel>

                        <Select2
                          id="userId"
                          name="userId"
                          placeholder="Všichni uživatelé"
                          onChange={(option) => {
                            setFilterValue('userId', option?.value)
                          }}
                          value={userSelectOptions.find((u) => u.value == getFilterValue('userId'))}
                          options={userSelectOptions}
                          isLoading={isLoadingUsers}
                          loadingMessage={() => 'Načítání uživatelů...'}
                          isClearable={true}
                        />
                      </FormControl>
                      <FormControl marginTop={'1rem'}>
                        <FormLabel htmlFor="submit"></FormLabel>
                        <Button type="submit" colorScheme="primary" onClick={() => {
                          setProdChartFilteredValues(prodChartFilterValues);
                        }}>
                          <Search />
                          Vyhledat
                        </Button>
                      </FormControl>
                    </Flex>
                  </div>
                )}
                {isLoadingProductionChart ? (
                  <Spinner />
                ) : (
                  <LineChart width={1120} height={400} data={productionChartData?.data}>
                    <CartesianGrid stroke="#f0f0f0" />
                    <XAxis dataKey="name" fontSize="11px" tickMargin={10} tickSize={0} />
                    <YAxis
                      fontSize="11px"
                      tickMargin={10}
                      tickSize={0}
                      width={80}
                      tickFormatter={(value) => formatPrice({ price: value })}
                    />
                    <Tooltip content={(props) => <PriceTooltip {...props} />} />
                    <Line type="monotone" dataKey="value" stroke={primaryColor} strokeWidth={2}  />
                  </LineChart>
                  )}
              </div>
          </CardBody>
        </Card>
      </Container>
    </Section>
  )
}
