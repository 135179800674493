import queryString from 'query-string'
import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IOrganizationItem } from '../../interfaces/entities/IOrganization'
import { IFlexiGroup } from '../../interfaces/entities/IFlexiGroup'

export const useGetFlexiGroupPagination = (
  page: number | undefined,
  limit: number,
  active?: number,
  search?: string,
) => {
  const query = queryString.stringify(
    {
      active,
      search
    },
    {
      skipEmptyString: true,
    },
  )
  const flexiGroupPagination: string = `${ApiRouteEnum.FLEXI_LIST}?${query}`
  return useQuery(
    [ApiRouteEnum.FLEXI_LIST, query],
    () => api.get<IFlexiGroup>(flexiGroupPagination),
    {
      retry: false,
    },
  )
}
