import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IOrganizationItem } from '../../interfaces/entities/IOrganization'
import queryString from 'query-string'
import { OrganizationTypeEnum } from '../../enums/OrganizationTypeEnum'
import { bool } from 'yup'
import { useQueryLimit } from '../../hooks/useIsDevMode'

export const useGetOrganizations = ({
  page,
  limit,
  search,
  isActive,
  organizationType,
  flexiGroup,
  notInFlexiGroup,
  showAll
}: {
  page?: number | undefined,
  limit?: number,
  search?: string,
  isActive?: boolean,
  organizationType?: OrganizationTypeEnum,
  flexiGroup?: string,
  notInFlexiGroup?: number,
  showAll?: number,
}) => {
  const params = {
    page,
    search,
    limit: useQueryLimit(limit),
    isActive,
    organizationType,
    flexiGroup,
    notInFlexiGroup,
    showAll
  };

  const query = queryString.stringify(
    params,
    {
      skipEmptyString: true,
    },
  )

  return useQuery(
    [ApiRouteEnum.ORGANIZATION, btoa(JSON.stringify(params))],
    () => api.get<IOrganizationItem>(`${ApiRouteEnum.ORGANIZATION}?${query}`),
    {
      retry: false,
    },
  )
}
