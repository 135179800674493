import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import IModalEmailFormValuesCompleted from '../../interfaces/entities/IModalEmailFormValuesCompleted'

export const usePostContractSendMail = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
) => {
  const queryClient = useQueryClient();
  return useMutation((values?: IModalEmailFormValuesCompleted) => api.post(`${ApiRouteEnum.CONTRACT}/${id}/send-email`, values), {
    mutationKey: [`${ApiRouteEnum.OFFERS}/${id}/send-email`],
    onSuccess: (data) => {
      successCallBack(data.data)
      queryClient.invalidateQueries([ApiRouteEnum.SENT_EMAILS, id]);
    },
    onError: (error) => {
      errorCallBack(error);
      queryClient.invalidateQueries([ApiRouteEnum.SENT_EMAILS, id]);
    },
  })
}
