import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { OrganizationEdit } from '../../containers/organization/OrganizationEdit'
import { organizations } from '../../data/organizations'
import { FlexiGroupEdit } from '../../containers/flexiGroup/FlexiGroupEdit'

export const FlexiGroupEditPage = () => {
  return (
    <Section>
      <Container maxWidth={'2xl'}>
        <FlexiGroupEdit />
      </Container>
    </Section>
  )
}
