import { Section } from '../../components/Section'
import { Container } from '../../components/Container'
import { FlexiGroupCreate } from '../../containers/flexiGroup/FlexiGroupCreate'

export const FlexiGroupCreatePage = () => {
  return (
    <Section>
      <Container maxWidth={'2xl'}>
        <FlexiGroupCreate />
      </Container>
    </Section>
  )
}
