import React from 'react'
import { Container as ChakraContainer } from '@chakra-ui/react'
import * as _chakra_ui_system from '@chakra-ui/system'

type ContainerProps = {
  children: React.ReactNode,
  maxWidth?: string
}

export const Container = ({ children, ...rest }: ContainerProps) => {
  return <ChakraContainer {...rest}>{children}</ChakraContainer>
}
