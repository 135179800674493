import { Heading } from '@chakra-ui/react'
import { PageContent } from '../PageContent'
import { FlexiGroupEditForm } from './FlexiGroupEditForm'

export const FlexiGroupEdit = () => {
  return (
    <PageContent>
      <Heading>Upravit flexibilní skupinu</Heading>

      <FlexiGroupEditForm />
    </PageContent>
  )
}
