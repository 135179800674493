import { Form, Formik } from 'formik'
import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { FormGroup } from '../../components/FormGroup'
import {
  Button,
  FormControl,
  FormErrorMessage,
  Grid, Switch,
  useToast,
} from '@chakra-ui/react'
import { FormLabel } from '../../components/FormLabel'
import { InputGroup } from '../../components/InputGroup'
import { TextareaGroup } from '../../components/TextareaGroup'
import { ArrowLeft } from '../../icons'
import { validateAndScroll } from '../../model/utils/formHelpers'
import React from 'react'
import { IFlexiGroupEditFormValues } from '../../model/interfaces/entities/IFlexiGroup'
import { RequiredField } from '../RequiredField'
import { FlexiGroupEditSchema } from './FlexiGroupEditValidation'
import { SwitchControl } from '../../components/SwitchControl'

export const FlexiGroupForm: React.FC<{
  initialData: IFlexiGroupEditFormValues
  onSubmitForm: (values: any) => void
  onGoBack: () => void
  isLoading: boolean
}> = ({ initialData, onSubmitForm, onGoBack, isLoading }) => {
  const toastMessage = useToast()

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialData}
      onSubmit={(values) => onSubmitForm(values)}
      validationSchema={FlexiGroupEditSchema}
    >
      {({ handleChange, errors, touched, values, setValues, validateForm }) => {
        return (
          <Form>
            <FormSection gridTemplateColumns={'unset'}>
              <FormSectionBody>
                <FormGroup>
                  <FormControl isInvalid={touched.name && !!errors.name}>
                    <FormLabel htmlFor="username">
                      Název
                      <RequiredField />
                    </FormLabel>
                    <InputGroup
                      id="name"
                      name="name"
                      placeholder="Název flexibilní skupiny"
                      autoComplete="name"
                      onChange={handleChange}
                      defaultValue={values.name}
                    />
                    {touched.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                  </FormControl>
                  <FormControl variant="fullWidth">
                    <FormLabel>Popis</FormLabel>

                    <TextareaGroup
                      id="description"
                      name="description"
                      placeholder="Popis flexibilní skupiny"
                      autoComplete="description"
                      onChange={handleChange}
                      defaultValue={values.description}
                    ></TextareaGroup>
                    {touched.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                  </FormControl>
                  <FormControl>
                    <FormLabel>Aktivní</FormLabel>

                    <SwitchControl>
                      <FormLabel htmlFor="active" variant="switch">
                        Ne
                      </FormLabel>
                      <Switch
                        id="active"
                        name="active"
                        size="md"
                        autoComplete="active"
                        onChange={handleChange}
                        isChecked={!!values.active}
                      />
                      <FormLabel htmlFor="active" variant="switch">
                        Ano
                      </FormLabel>
                    </SwitchControl>
                    {touched.active && <FormErrorMessage>{errors.active}</FormErrorMessage>}
                  </FormControl>
                </FormGroup>
              </FormSectionBody>
            </FormSection>


            <Grid
              sx={{
                width: '100%',
                gridTemplateColumns: 'auto auto',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button variant="ghost" type="button" onClick={onGoBack}>
                <ArrowLeft color="secondary.500" />
                Zpět
              </Button>
              <Button
                colorScheme="primary"
                type="submit"
                isLoading={isLoading}
                onClick={() => validateAndScroll(values, validateForm, toastMessage)}
              >
                Uložit
              </Button>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
