import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { mode, PartsStyleFunction } from '@chakra-ui/theme-tools'

const parts = ['container', 'metadata', 'title', 'textContent']

const baseStyle: PartsStyleFunction = (props: any) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      bg: mode('white', 'gray.800')(props),
      placeContent: 'start',
      placeItems: 'start',
      alignContent: 'space-between',
      p: { base: 2, md: 4 },
    },
    metadata: {
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'start',
      justifyItems: 'start',
      gap: 1,
    },
    tagDateWrapper: {
      display: 'grid',
      placeContent: 'center',
      placeItems: 'start',
    },
    title: {
      color: 'primary.700',
      fontSize: 'md',
    },
    textContent: {
      fontSize: 'sm',
      lineHeight: 1.5,
      px: { base: 2 },
    },
  }
}

const variantList = () => {
  return {
    container: {
      p: { base: 2, md: 3 },
      borderBottom: '1px solid',
      borderBottomColor: 'gray.200',
      '&:last-of-type': {
        borderBottom: 'none',
        borderBottomRadius: 'md',
      },
      width: '100%'
    },
    tagDateWrapper: {
      gap: 1,
      gridAutoFlow: 'column',
      alignItems: 'center',
    },
  }
}

const variantGrid = (props: any) => {
  const { colorScheme: c } = props

  return {
    container: {
      p: { base: 2, md: 4 },
      borderLeftColor: `${c}.500`,
      borderLeftWidth: '2px',
      borderRadius: 'md',
      boxShadow: 'sm',
    },
    tagDateWrapper: { gap: 1 },
  }
}

export const variants = {
  list: variantList,
  grid: variantGrid,
}

const defaultProps = {
  variant: 'list',
}

export const NewsItem: ComponentMultiStyleConfig = {
  parts,
  baseStyle,
  defaultProps,
  variants,
}
