import { Box, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { OrganizationEditFormValues } from '../../model/interfaces/entities/IOrganizationEditFormValues'
import { usePutOrganizations } from '../../model/api/organization/usePutOrganizations'
import { IOrganization } from '../../model/interfaces/entities/IOrganization'
import { useGetCompanies } from '../../model/api/company/useGetCompany'
import { useGetOrganizationsId } from '../../model/api/organization/useGetOrganizationId'
import { Loading } from '../../components/Loading'
import transformPersonalNumbers from '../../model/utils/transformPersonalNumbers'
import { useIsSupervisorOrAdmin } from '../../model/utils/userUtils'
import { useEffect } from 'react'
import { FlexiGroupForm } from './FlexiGroupForm'
import { IFlexiGroupEditFormValues } from '../../model/interfaces/entities/IFlexiGroup'
import { useGetFlexiGroupId } from '../../model/api/organization/useGetFlexiGroupId'
import { usePostFlexiGroup } from '../../model/api/flexiGroup/usePostFlexiGroup'
import { usePatchFlexiGroup } from '../../model/api/flexiGroup/usePatchFlexiGroup'

export const FlexiGroupEditForm = () => {
  const navigate = useNavigate()
  const toastMessage = useToast()
  const { id } = useParams<'id'>()
  const { data, isLoading: organizationIsLoading } = useGetFlexiGroupId(id)
  const { data: dataCompany } = useGetCompanies()
  const userIsSupervisor = useIsSupervisorOrAdmin()
  useEffect(() => {
    if (userIsSupervisor === false) {
      navigate('/404')
    }
  }, [userIsSupervisor, navigate])

  const successCallBack: (data: IOrganization) => void = (data: any) => {
    navigate(`/flexi/${id}`)
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Došlo k chybě',
      description: error + '! Please try again!',
      status: 'error',
      isClosable: true,
    })
  }

  const { mutate, isLoading } = usePatchFlexiGroup(successCallBack, errorCallBack, id as string);

  const onSubmitForm: (formValues: IFlexiGroupEditFormValues) => void = (
    formValues: IFlexiGroupEditFormValues,
  ) => {
    mutate(formValues)
  }

  if (organizationIsLoading || !data || !dataCompany) {
    return <Loading />
  }
  return (
    <Box>
      <FlexiGroupForm
        initialData={
          data.data as IFlexiGroupEditFormValues
        }
        onSubmitForm={onSubmitForm}
        onGoBack={() => navigate(`/flexi/${id}`)}
        isLoading={isLoading}
      />
    </Box>
  )
}
