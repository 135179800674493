import { Heading, useToast } from '@chakra-ui/react'
import { PageContent } from '../PageContent'
import { FlexiGroupCreateForm } from './FlexiGroupCreateForm'
import { useNavigate } from 'react-router-dom'
import { IOrganization } from '../../data/organizations'
import { usePatchFlexiGroup } from '../../model/api/flexiGroup/usePatchFlexiGroup'
import { usePostFlexiGroup } from '../../model/api/flexiGroup/usePostFlexiGroup'

export const FlexiGroupCreate = () => {
  return (
    <PageContent>
      <Heading>Vytvořit flexibilní skupinu</Heading>

      <FlexiGroupCreateForm />
    </PageContent>
  )
}
