import { Badge, Button, Flex, Heading, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { ArrowLeft, ChevronRight, Edit, TextOrderedList, TextUnorderedList } from '../../icons'
import { useEffect } from 'react'
import { Loading } from '../../components/Loading'
import { useIsSupervisorOrAdmin } from '../../model/utils/userUtils'
import { useGetFlexiGroupId } from '../../model/api/organization/useGetFlexiGroupId'
import { FlexiGroupDetail } from '../../containers/flexiGroup/FlexiGroupDetail'

export const FlexiGroupDetailPage = () => {
  const navigate = useNavigate()
  const toastMessage = useToast()
  const { id } = useParams<'id'>();
  const { data, isLoading, isError, error } = useGetFlexiGroupId(id)
  const userIsSupervisor = useIsSupervisorOrAdmin()
  useEffect(() => {
    isError &&
      toastMessage({
        title: 'Došlo k chybě',
        description: error + '! Please try again!',
        status: 'error',
        isClosable: true,
      })
  }, [isError])

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Section>
          <Container>
            <PageContent>
              <PageToolbar
                leftElements={
                  <Flex sx={{ gap: 4, alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Heading as="h1">
                      {data?.data.name}
                      <Badge colorScheme={'blue'} ml={3} sx={{ boxShadow: 'sm', }} mb={1}>Speciální</Badge>
                      {data?.data.active && (
                        <Badge colorScheme={'green'} ml={3} sx={{ boxShadow: 'sm', }} mb={1}>Aktivní</Badge>
                      )}
                    </Heading>
                    <Flex sx={{ alignItems: 'center'}}>
                      <TextUnorderedList mr={2} />
                      {data?.data.name}
                    </Flex>
                  </Flex>
                }
                rightElements={
                  <Flex sx={{ gap: 4 }}>
                    <Button variant="ghost" onClick={() => navigate('/flexi')}>
                      <ArrowLeft color="secondary.500" />
                      Zpět na přehled flexibilních skupin
                    </Button>
                    <Button
                      hidden={!userIsSupervisor}
                      colorScheme="primary"
                      onClick={() => navigate(`/flexi/${id}/edit`)}
                    >
                      <Edit />
                      Upravit flexibilní skupinu
                    </Button>
                  </Flex>
                }
              />

              <FlexiGroupDetail />
            </PageContent>
          </Container>
        </Section>
      )}
    </>
  )
}
