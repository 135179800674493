import { Flex, Heading, useToast } from '@chakra-ui/react'

import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { PageContent } from '../../containers/PageContent'
import { PageToolbar } from '../../containers/PageToolbar'
import { NewsForm } from '../../containers/news/NewsForm'
import { useEffect, useState } from 'react'
import { INewsNewItemFormValues } from '../../model/interfaces/entities/INews'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetNewsDetails } from '../../model/api/news/useGetNewsDetails'
import { usePatchNews } from '../../model/api/news/usePatchNews'

const NewsEditItem = () => {
  const { id } = useParams()
  const toastMessage = useToast()
  const navigate = useNavigate()
  const { data: newsData, isLoading: newsDataIsLoading } = useGetNewsDetails(id)
  const [initialValues, setInitialValues] = useState<INewsNewItemFormValues>({
    date: '',
    title: '',
    content: '',
    isActive: false,
    priority: undefined,
    type: undefined,
    company: undefined,
    author: 'Test User',
    tagLabel: 'EpicTag',
    status: 'info',
  })

  useEffect(() => {
    if (newsData) {
      setInitialValues({
        ...initialValues,
        title: newsData.data.title,
        content: newsData.data.content ? newsData.data.content : '',
        priority: newsData.data.priority,
        type: newsData.data.type,
        company: newsData.data.company?.id,
        date: newsData.data.date ? new Date(newsData.data.date) : new Date(),
        isActive: newsData.data.isActive ? newsData.data.isActive : false,
      })
    }
  }, [newsData])

  const successCallBack: () => void = () => {
    navigate('/news/admin')
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Error',
      description: error.response.data,
      status: 'error',
      isClosable: true,
    })
  }
  const { mutate } = usePatchNews(successCallBack, errorCallBack, id)

  const onSubmitForm = (values: INewsNewItemFormValues) => {
    mutate(values)
  }
  return (
    <Section>
      <Container>
        <PageContent>
          <PageToolbar
            leftElements={
              <Flex sx={{ gap: 4, alignItems: 'center' }}>
                <Heading as="h1">Edit news</Heading>
              </Flex>
            }
          />
          <NewsForm
            initialValues={initialValues}
            onSubmitForm={onSubmitForm}
            isLoading={newsDataIsLoading}
          />
        </PageContent>
      </Container>
    </Section>
  )
}

export default NewsEditItem
