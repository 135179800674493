import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Box, BoxProps, Grid, Radio, RadioGroup, Switch } from '@chakra-ui/react'

import { FormLabel } from '../components/FormLabel'
import {
  ExtraInsuranceSmeItemIconsEnum,
  getExtraInsuranceSmeIcon,
} from '../utils/getExtraInsuranceSmeIcon'
import { Step2FormValues } from './sme/Step2Form'
import { FormControl } from '../components/FormControl'
import { FormIsDisabledContext } from '../model/contexts/FormIsDisabledContext'
import { RadioStack } from '../components/RadioStack'

const ExtraInsuranceItem = ({
  children,
  label,
  tooltip,
  icon,
  sx,
}: {
  children: React.ReactNode
  label: string
  tooltip?: string
  icon: ExtraInsuranceSmeItemIconsEnum | false
  sx?: BoxProps['sx']
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, ...sx }}>
      {icon && getExtraInsuranceSmeIcon(icon, '48px', 'secondary.500')}
      <FormControl sx={{}}>
        <FormLabel tooltip={tooltip}>{label}</FormLabel>
        {children}
      </FormControl>
    </Box>
  )
}

export const ExtraInsuranceSme = ({
  showIcons = true,
  handleChange,
  setFieldValue,
  values,
}: {
  showIcons?: boolean
  values: Step2FormValues
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  setFieldValue: (field: string, value: any) => void
  touched: any
  errors: any
}) => {
  const formIsDisabled = useContext(FormIsDisabledContext)
  const [isCheckedAssistance, setIsCheckedAssistance] = useState(values.assistance !== '')
  const handleAssistanceCheck = () => {
    setIsCheckedAssistance((prev) => !prev)
  }
  useEffect(() => {
    if (isCheckedAssistance) {
      setFieldValue('assistance', values.assistance === '' ? 'basic' : values.assistance)
      return
    }

    setFieldValue('assistance', '')
  }, [isCheckedAssistance])
  return (
    <Box sx={{ w: '100%', display: 'grid', gap: 4 }}>
      <Box
        sx={{
          w: '100%',
          display: 'grid',
          gridTemplateColumns: { base: '1fr', lg: '1fr 1fr 1fr' },
          gap: 8,
          placeItems: 'start',
        }}
      >
        <Grid sx={{ gap: 8 }}>
          <ExtraInsuranceItem
            label="Povodeň, záplava"
            tooltip="Povodeň, záplava"
            icon={showIcons && ExtraInsuranceSmeItemIconsEnum.Flood}
          >
            <Switch
              name="flood"
              isChecked={values.flood}
              onChange={(e) => {
                setFieldValue('flood', e.target.checked)
                handleChange(e)
              }}
              isDisabled={formIsDisabled}
            />
          </ExtraInsuranceItem>
        </Grid>
        <Grid sx={{ gap: 8 }}>
          <ExtraInsuranceItem
            label="Asistence"
            tooltip="Asistence"
            icon={showIcons && ExtraInsuranceSmeItemIconsEnum.Assistance}
          >
            <Switch
              name="assistance"
              isChecked={isCheckedAssistance}
              onChange={handleAssistanceCheck}
              isDisabled={formIsDisabled}
            />
          </ExtraInsuranceItem>
          {isCheckedAssistance && (
            <RadioGroup name="assistance" value={values.assistance} isDisabled={formIsDisabled}>
              <RadioStack variant="stackedTile">
                <Radio value="basic" onChange={(e) => setFieldValue('assistance', e.target.value)}>
                  Zakladní
                </Radio>
                <Radio
                  value="extended"
                  onChange={(e) => setFieldValue('assistance', e.target.value)}
                >
                  Rozšířená
                </Radio>
              </RadioStack>
            </RadioGroup>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
