import { Icon } from '@chakra-ui/react'

export const SelectArrowDown = (props: any) => (
	<Icon boxSize={props.boxSize || "20px"} {...props} viewBox="0 0 10 7" fill="none">
<path d="M1.63763 0.599998C1.48666 0.600031 1.33879 0.642779 1.21109 0.723303C1.08339 0.803828 0.981081 0.918841 0.915987 1.05505C0.850893 1.19127 0.825669 1.34312 0.843231 1.49306C0.860792 1.643 0.920422 1.78491 1.01523 1.9024L4.06643 5.6808C4.17891 5.82009 4.32114 5.93245 4.48268 6.00963C4.64422 6.08682 4.82099 6.12688 5.00003 6.12688C5.17906 6.12688 5.35583 6.08682 5.51738 6.00963C5.67892 5.93245 5.82115 5.82009 5.93363 5.6808L8.98563 1.9024C9.08044 1.78491 9.14006 1.643 9.15763 1.49306C9.17519 1.34312 9.14996 1.19127 9.08487 1.05505C9.01978 0.918841 8.91747 0.803828 8.78977 0.723303C8.66207 0.642779 8.5142 0.600031 8.36323 0.599998H1.63763Z" fill="currentColor"/>
	</Icon>
)


export const SelectArrowDownString=`<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.63763 0.599998C1.48666 0.600031 1.33879 0.642779 1.21109 0.723303C1.08339 0.803828 0.981081 0.918841 0.915987 1.05505C0.850893 1.19127 0.825669 1.34312 0.843231 1.49306C0.860792 1.643 0.920422 1.78491 1.01523 1.9024L4.06643 5.6808C4.17891 5.82009 4.32114 5.93245 4.48268 6.00963C4.64422 6.08682 4.82099 6.12688 5.00003 6.12688C5.17906 6.12688 5.35583 6.08682 5.51738 6.00963C5.67892 5.93245 5.82115 5.82009 5.93363 5.6808L8.98563 1.9024C9.08044 1.78491 9.14006 1.643 9.15763 1.49306C9.17519 1.34312 9.14996 1.19127 9.08487 1.05505C9.01978 0.918841 8.91747 0.803828 8.78977 0.723303C8.66207 0.642779 8.5142 0.600031 8.36323 0.599998H1.63763Z" fill="#003D66"/>
</svg>
`;