import { Box, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { usePostOrganizations } from '../../model/api/organization/usePostOrganizations'
import { initialValues } from '../../utils/FlexiGroupEditInitialValues'
import { useEffect } from 'react'
import { Loading } from '../../components/Loading'
import { useIsSupervisorOrAdmin } from '../../model/utils/userUtils'
import { FlexiGroupForm } from './FlexiGroupForm'
import { IFlexiGroup, IFlexiGroupEditFormValues } from '../../model/interfaces/entities/IFlexiGroup'
import { usePostCreateFlexiGroup } from '../../model/api/flexiGroup/usePostCreateFlexiGroup'
import { usePostFlexiGroup } from '../../model/api/flexiGroup/usePostFlexiGroup'

export const FlexiGroupCreateForm = () => {
  const toastMessage = useToast()
  const navigate = useNavigate()
  const userIsSupervisor = useIsSupervisorOrAdmin()
  useEffect(() => {
    if (userIsSupervisor === false) {
      navigate('/404')
    }
  }, [userIsSupervisor, navigate])

  const successCallBack: (data: IFlexiGroup) => void = (data: any) => {
    navigate(`/flexi/${data.id}`)
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Chyba',
      description: error.response.data.error,
      status: 'error',
      isClosable: true,
    })
  }

  const { mutate, isLoading } = usePostFlexiGroup(successCallBack, errorCallBack)

  if (isLoading) {
    return <Loading />
  }

  const onSubmitForm: (formValues: IFlexiGroupEditFormValues) => void = (
    formValues: IFlexiGroupEditFormValues,
  ) => {

    mutate(formValues)
  }

  return (
    <Box>
      <FlexiGroupForm
        initialData={initialValues}
        onSubmitForm={onSubmitForm}
        onGoBack={() => navigate('/flexi')}
        isLoading={isLoading}
      />
    </Box>
  )
}
