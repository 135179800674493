import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { useQuery } from '@tanstack/react-query'
import api from '../api'

export const enum EmailStatus {
  EMAIL_STATUS_SENT_OK = 1,
  EMAIL_STATUS_SENT_FAIL = 2,
}

export const enum EmailSendType {
  SENT_MANUALLY = 1,
  SENT_AUTOMATICALLY = 2,
}

export interface IEmail {
  contract: number,
  recipientEMailAddress: string,
  sendType: EmailSendType,
  sentAt: string,
  sentBy: number,
  status: EmailStatus,
  updatedAt: string
}

interface IEmails {
  count: number,
  items: IEmail[]
}

const useGetSentEmails = (id?: string) => {
  return useQuery(
    [ApiRouteEnum.SENT_EMAILS, id],
    () => api.get<IEmails>(ApiRouteEnum.SENT_EMAILS.replace('<id>', id ?? '')),
    {
      retry: false
    },
  )
}

export default useGetSentEmails
